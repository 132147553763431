<template>
  <div class="content">
    <div class="row">
      <div class="col-2">
          <el-dropdown style="width:100%" @command="handleCommand">
            <el-button type="primary" size="mini" style="width:100%">
              Acciones<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="descarga" v-if="permisos.down">Descargar Selección</el-dropdown-item>
              <el-dropdown-item command="filtrar">Filtrar por ID's</el-dropdown-item>
              <el-dropdown-item command="filtrarNud">Filtrar por NUD's</el-dropdown-item>
              <el-dropdown-item command="callCenter">Subir a CallCenter</el-dropdown-item>
              <el-dropdown-item command="loadJson">Subir JSON</el-dropdown-item>
              <el-dropdown-item command="asignarSuper">Asignar Supervisor</el-dropdown-item>
              <el-dropdown-item command="dowFotos">Descargar Fotos</el-dropdown-item>
              <el-dropdown-item command="cambiar" v-if="permisos.mass">Cambios Masivos</el-dropdown-item>
              <el-dropdown-item command="reAsigna">Re-Asignar</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        Regiones:
          <el-select v-model="region" placeholder="REGIONES" size="mini" @change="getGerencias(0)">
            <el-option
              v-for="item in regiones"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
        <span v-if="gerencias.length != 0">Gerencias:</span>
          <el-select v-if="gerencias.length != 0" v-model="gerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(0)">
            <el-option
              v-for="item in gerencias"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="cedis.length != 0">Cedis:</span>
          <el-select v-if="cedis.length != 0" v-model="cedi" placeholder="CEDIS" size="mini" @change="getPreventa(0)">
            <el-option
              v-for="item in cedis"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="usuarios.length != 0">Usuario:</span>
          <el-select v-if="usuarios.length != 0" v-model="usuario" placeholder="USUARIO" size="mini">
            <el-option
              v-for="item in usuarios"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="preventas.length != 0">Rutas:</span>
          <el-select v-if="preventas.length != 0" v-model="pre" placeholder="RUTAS" size="mini" multiple collapse-tags>
            <el-option
              v-for="item in preventas"
              :key="item.i+'pr'"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Estatus Encuesta:
          <el-select v-model="estatus" placeholder="ESTATUS ENCUESTA" size="mini" collapse-tags>
            <el-option
              v-for="item in status"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Estatus Validación:
          <el-select v-model="validado" placeholder="ESTATUS VALIDACIÓN" size="mini" collapse-tags>
            <el-option
              v-for="item in validados"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Frecuencia:
          <el-select v-model="frecuencia" placeholder="FRECUENCIA" size="mini" multiple collapse-tags>
            <el-option
              v-for="item in frecuencias"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Fechas:
            <el-date-picker
              type="daterange"
              placeholder="Date Time Picker"
              v-model="dateTimePicker"
               size="mini"
               format="dd-MM-yyyy"
               value-format="yyyy-MM-dd"
               prefix-icon="false"
               class="el-input__icon"
               range-separator="A"
               style="font-size:8px; padding:3px 0px 3px 6px;"
               
            >
            </el-date-picker>
          <el-button size="mini" type="primary" class="mt-3" style="width:100%"
          @click="filtrar"
          >
            Filtrar
          </el-button>
        </div>
      <div class="col-10">
          <div>Registros por Asignar: <span><b>{{restantes}}</b></span></div>
          <v-data-table
            dense
            :headers="headers"
            :items="desserts"
            item-key="valCar_id"
            :item-class="rowClass"
            :options="pagination"
            hide-default-footer
            @dblclick:row="eventRow"
          >
          <template v-slot:item.acciones="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="eventEditRow(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                class="mr-2"
                @click="eventCommentRow(item)"
              >
                mdi-comment-outline
              </v-icon>
          </template>
          </v-data-table>
          <div
            slot="footer"
            class="col-12 my-2 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Registros <b>{{ start + 1 }}</b> A <b>{{ start + itemsT }}</b> de <b>{{ total }}</b>
              </p>
            </div>
            <b-pagination
              v-model="pagination.currentPage"
              :per-page="pagination.limit"
              :total-rows="total"
              size="sm"
              @change="newPagina"
              ></b-pagination>
          </div>
          <!--<div class="row pt-2 justify-content-between">
            <div class="col-4">
              Registros de {{ start + 1 }} A {{ start + itemsT }} de {{ total }}
            </div>
            <div class="col-6">
              <v-pagination
                v-model="pagination.page"
                :length="pageCount"
                :total-visible="7"
                @input="newPagina($event)"
              ></v-pagination>
            </div>
          </div>-->
      </div>
    </div>

    <!-- modals-->
    <el-dialog
      title="Filtrar Registros"
      :visible.sync="dgFiltrar"
      width="30%">
      <p>Ingrese los Id's</p>
      <el-input
        type="textarea"
        :rows="2"
        placeholder="Id's separados por coma (,)"
        v-model="idsFiltrar">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dgFiltrar = false">Cancelar</el-button>
        <el-button type="primary" @click="filtrarRegistros">Filtrar</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="Filtrar Registros NUD's"
      :visible.sync="dgFiltrarNuds"
      width="30%">
      <p>Ingrese los NUD's</p>
      <el-input
        type="textarea"
        :rows="2"
        placeholder="NUD's separados por coma (,)"
        v-model="nudsFiltrar">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dgFiltrarNuds = false">Cancelar</el-button>
        <el-button type="primary" @click="filtrarRegistrosNuds">Filtrar</el-button>
      </span>
    </el-dialog>

    <el-dialog
       title="Subir Call Center"
       :visible.sync="dgCallCenter"
       width="30%">
       <el-upload
         class="upload-demo mb-3"
         :auto-upload="false"
         :on-change="callCenterArchivo"
         >
         <el-button size="small" type="primary">Clic para seleccionar archivo</el-button>
         <!--<div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>-->
       </el-upload>
       <!--<el-input
         type="textarea"
         :rows="2"
         placeholder="Resultados"
         v-model="resultadoMasivo">
       </el-input>-->
       <span slot="footer" class="dialog-footer">
         <el-button @click="dgCallCenter = false">Cancelar</el-button>
         <el-button type="primary" @click="subirCallCenter">Enviar</el-button>
       </span>
    </el-dialog>

    <el-dialog
       title="Cambios Masivos"
       :visible.sync="dgCambios"
       width="30%">
       <el-upload
         class="upload-demo mb-3"
         :auto-upload="false"
         :on-change="masivoArchivo"
         >
         <el-button size="small" type="primary">Clic para seleccionar archivo</el-button>
         <!--<div slot="tip" class="el-upload__tip">Solo archivos jpg/png con un tamaño menor de 500kb</div>-->
       </el-upload>
       <!--<el-input
         type="textarea"
         :rows="2"
         placeholder="Resultados"
         v-model="resultadoMasivo">
       </el-input>-->
       <span slot="footer" class="dialog-footer">
         <el-button @click="dgCambios = false">Cancelar</el-button>
         <el-button type="primary" @click="cambiosMasivos">Enviar</el-button>
       </span>
     </el-dialog>

    <el-dialog
      title="Re-Asignar Registros"
      :visible.sync="dgAsignar"
      width="30%">
        <div data-app>
          <v-select v-model="callCenter" :items="itemsCallCenter"
            :rules="[v => !!v || 'Seleccione un Call Center']"
            label="Call Center"
            item-value="i"
            item-text="d"
            required
          ></v-select>
          <p>Ingrese los Ids</p>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="Ids separados por coma (,)"
            v-model="idsReasignar"
            class="pb-4">
          </el-input>
          <div class="text-right">
            <el-button @click="dgAsignar = false">Cancelar</el-button>
            <el-button type="primary" @click="reAsignar">Asignar</el-button>
          </div>
        </div>
    </el-dialog>

    <el-dialog
          :title="currentClient"
          :visible.sync="dgDetalle"
          top="10px"
          width="60%">
            <div class="row">
                <!--<div class="col-md-4 col-form-label">
                <p>
                <center><b>Aviso clientes</b></center>
                </p>
                <p>
                1. Responsable del tratamiento de sus datos personales
                </p>
                <p>
                  Grupo Gepp, S.A.P.I. de C.V., (“GEPP”), , persona moral, debidamente constituida conforme a las leyes de la República Mexicana, 
                  con domicilio en Av. Santa Fe No. 485 piso 4, Col. Cruz Manca, México, Ciudad de México, Cuajimalpa de Morelos, CP 05349,
                  es la persona responsable del tratamiento de sus datos personales.
                </p>
                  <center><img :src="currentImagenFirma" alt="" height="200px" /></center>
                  <div class="text-center">
                    <el-button type="primary" @click="descargaContrato">Descargar Contrato</el-button>
                  </div>
                </div>-->
                <!--<div class="col-md-2 col-form-label text-center">
                  <img :src="currentImagenCliente" alt="" height="350px" />
                </div>-->
                <div class="col-md-6 col-form-label text-center">
                  <img :src="currentImagen" alt="" height="500px" />
                </div>
                <!--<div class="col-md-2 col-form-label text-center">
                  <img :src="currentImagenTicket" alt="" height="350px" />
                </div>
                <div class="col-md-2 col-form-label text-center">
                  <img :src="currentImagenFirma" alt="" height="350px" />
                </div>-->
                  <div class="col-md-6">
                      <div id="customSkinMap" class="map" ></div>
                  </div>
            </div>
    </el-dialog>

    <el-dialog
      title="Asignar Registros"
      :visible.sync="dgAsignarReg"
      width="35%">
      <div data-app>
          <v-form ref="asignarReg" v-model="asignarRegDg" lazy-validation >
          <div class="row no-gutters">
            <div class="col-6 pr-2">
              Región:
                <el-select v-model="asigSuper.region" placeholder="REGIONES" size="mini" @change="getGerencias(2)">
                  <el-option
                    v-for="item in superRegiones"
                    :key="item.i"
                    :label="item.d"
                    :value="item.i">
                  </el-option>
                </el-select>
            </div>
            <div class="col-6 pl-2">
              Gerencia:
              <el-select :disabled="superGerencias.length == 0" v-model="asigSuper.gerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(2)">
                <el-option
                  v-for="item in superGerencias"
                  :key="item.i"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-6 pr-2">
              Cedi:
              <el-select :disabled="superCedis.length == 0" v-model="asigSuper.cedi" placeholder="CEDIS" size="mini" >
                <el-option
                  v-for="item in superCedis"
                  :key="item.i"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>
            </div>
            <div class="col-6 pl-2">
              <span>CAS:</span>
              <el-select v-model="asigSuper.callCenter" placeholder="Usuarios" size="mini" @change="restantesReg(1)">
                <el-option
                  v-for="item in itemsCallCenter"
                  :key="item.i+'us'"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-12">
              Fechas:
              <el-date-picker
                type="daterange"
                placeholder="Date Time Picker"
                v-model="asigSuper.dateTime"
                size="mini"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                prefix-icon="false"
                class="el-input__icon"
                range-separator="A"
                style="font-size:8px; padding:3px 0px 3px 6px;"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-6 pr-2">
              <br/>
              Por Asignar: <b><span style="font-size:20px"> {{ restantesSuper }} </span></b>
            </div>
            <div class="col-6 pl-2">
              <span>Asignar:</span>
                <el-input
                  type="number"
                  placeholder=""
                  v-model="asigSuper.asignar"
                  size="mini"
                  min="0"
                  onkeyup="value=value.replace(/[^\d]/g,0)"
                  >
                </el-input>
            </div>
          </div>
              <div class="row">
                <div class="col-md-12 mt-3 text-center">
                  <el-button type="primary" @click="asignarSuper" :disabled="asigSuper.asignar == null || asigSuper.asignar == 0" >Asignar Registros</el-button>
                </div>
              </div>
      </v-form>
      </div>
    </el-dialog>

    <el-dialog
      :title="tlComment"
      :visible.sync="dgComment"
      width="30%">
      <p>Comentario</p>
      <el-input v-model="contComment" disabled></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dgComment = false">Cerrar</el-button>
      </span>
    </el-dialog>

    <!-- EDITAR CLIENTE -->
    <el-dialog
      :visible.sync="showEdit"
      title="Editar Cliente"
      width="50%"
      top="20px">
      <div data-app>
        <v-form ref="editClient" v-model="editValid" lazy-validation >
          <div class="row no-gutters">
            <div class="col-3" style="font-size:14px">
              <b>Nud Cliente: </b>{{ clEditar.nud }}
            </div>
              <div class="col-5 text-left" style="font-size:14px">  
                <b>Modelo Cliente: </b>{{ clEditar.modelo }}
              </div>
              <div class="col-4 text-left" style="font-size:14px">  
                <b>Tipo Cliente: </b>{{ clEditar.tipo }}
              </div>
          </div>
          <div class="row">
              <div class="col-8 pr-2">
                <v-text-field dense
                  id="cl_edit-nombre"
                  v-model="clEditar.nombre"
                  style="font-size:11px"
                  :rules="nameRules" label="Nombre" required
                  @blur="v => cleanStringEdt(v.target.id, v.target.value)"
                  ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clEditar.telFijoU"
                  id="cl_edit-telFijoU"
                  style="font-size:11px"
                  :counter="10"
                  maxlength="10"
                  @blur="v => cleanNumberEdt(v.target.id, v.target.value)"
                  label="Teléfono 1 fijo"  ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clEditar.telCelD"
                  id="cl_edit-telCelD"
                  style="font-size:11px"
                  :counter="10"
                  maxlength="10"
                  label="Teléfono 2 celular"
                  @blur="v => cleanNumberEdt(v.target.id, v.target.value)"
                  ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <v-text-field dense
                  id="cl_edit-telFijoT"
                  v-model="clEditar.telFijoT"
                  style="font-size:11px"
                  :counter="10"
                  maxlength="10"
                  @blur="v => cleanNumberEdt(v.target.id, v.target.value)"
                  label="Teléfono 3 fijo"  ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clEditar.telCelC"
                  id="cl_edit-telCelC"
                  style="font-size:11px"
                  :counter="10"
                  maxlength="10"
                  label="Teléfono 4 celular"
                  @blur="v => cleanNumberEdt(v.target.id, v.target.value)"
                  ></v-text-field>
              </div>
          </div>
            <div class="row no-gutters">
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clEditar.contactAd"
                  style="font-size:11px"
                  label="Contacto Adicional"  ></v-text-field>
              </div>
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clEditar.correo"
                  style="font-size:11px"
                  label="Correo"  ></v-text-field>
              </div>
              <div class="col-6 pr-2">
                <v-text-field dense
                  v-model="clEditar.direccion"
                  id="cl_edit-direccion"
                  style="font-size:11px"
                  label="Dirección" required ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clEditar.nExt"
                  style="font-size:11px"
                  :rules="[v => !!v || 'Escriba Número']"
                  label="Num. Exterior" required ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clEditar.nInt"
                  style="font-size:11px"
                  label="Num. Interior" ></v-text-field>
              </div>
              <div class="col-5 pr-2">
                <v-text-field dense
                  v-model="clEditar.colonia"
                  id="cl_nuevo-colonia"
                  style="font-size:11px"
                  label="Colonia" required ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-4 pr-2">
                <v-text-field dense
                  v-model="clEditar.calle1"
                  id="cl_edit-calle1"
                  style="font-size:11px"
                  label="Calle 1" required ></v-text-field>
              </div>
              <div class="col-4 pr-2">
                <v-text-field dense
                  v-model="clEditar.calle2"
                  id="cl_edit-calle2"
                  style="font-size:11px"
                  label="Calle 2" required ></v-text-field>
              </div>
              <div class="col-1 pr-2">
                <v-text-field dense
                  v-model="clEditar.cp"
                  id="cl_edit-cp"
                  style="font-size:11px"
                  @blur="v => cleanNumberEdt(v.target.id, v.target.value)"
                  label="CP" required ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-3 pr-2">
                <v-select v-model="clEditar.status" :items="statusEdt"
                    :rules="[v => !!v || 'Sel... un Estatus']"
                    label="Estatus"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                  ></v-select>
              </div>

              <div class="col-3 pr-2">
                <v-select v-model="clEditar.valido" :items="gen"
                    :rules="[v => !!v || 'Sel... una opción']"
                    label="Validado"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                  ></v-select>
              </div>
              <div class="col-3 pr-2">
                <v-select v-model="clEditar.categoria" :items="catego"
                    :rules="[v => !!v || 'Sel... una categoría']"
                    label="Categoría"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                  ></v-select>
              </div>
          </div>
              <div class="row">
                <div class="col-md-12 mt-3 text-center">
                  <el-button type="primary" @click="actualizarCliente">Actualizar Información</el-button>
                </div>
              </div>
            </v-form>
      </div>
    </el-dialog>
    <!-- END EDITAR CLIENT -->
    <!-- Dialog Contraseña -->
    <el-dialog
      :title="titlePass"
      :visible.sync="dgPassword"
      width="30%">
      <p>Contraseña</p>
      <el-input v-model="pass" placeholder="Contraseña"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dgPassword = false">Cancelar</el-button>
        <el-button type="primary" @click="validateAction">Confirmar</el-button>
      </span>
    </el-dialog>
    <!---->
    <!-- end modals-->
    <v-overlay :value="overlay" z-index="2002">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { TimeSelect,
          DatePicker,
          Select,
          Option,
          Button,
          Dropdown, DropdownMenu, DropdownItem, Switch, Upload, Dialog, FormItem, Form } from 'element-ui';
import axios from 'axios';

import { Loader, LoaderOptions } from "google-maps";
const loader = new Loader("AIzaSyDnsLdCKdxoFbJMxVfZHMDnr_SB-L-T7nA");

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [FormItem.name]: FormItem,
    [Form.name]: Form
  },
  data() {
    return {
      overlay: false,
      uid: null,
      hash: null,
      lvl: null,
      loading: true,
      region: null,
      regiones: [
      ],
      gerencia: null,
      gerencias: [
      ],
      cedi: null,
      cedis: [
      ],
      usuario: null,
      usuarios: [],
      pre: null,
      preventas: [
        ],
      nuevos: [
      { i: -1, d: 'Todos' },
      { i:  0, d: 'Cartera' },
      { i:  1, d: 'Nuevos' }
        ],
      validado: null,
      validados: [
        { i: -1, d: 'Todos' },
        { i:  0, d: 'No Acepto' },
        { i:  1, d: 'Acepto Televenta' }
      ],
      valEdt: [
        { i:  0, d: 'No Acepto' },
        { i:  1, d: 'Acepto Televenta' }
      ],
      estatus: null,
      status: [
        { i: -1, d: 'Todos' },
        { i:  1, d: 'Alta' },
        { i:  2, d: 'No desea servicio' },
        { i:  3, d: 'Validado' },
        { i:  4, d: 'Pendiente' },
        { i:  5, d: 'Inconsistencia' },
        { i:  6, d: 'Progreso' }
      ],
      statusEdt: [
        { i:  1, d: 'Alta' },
        { i:  2, d: 'No desea servicio' },
        { i:  3, d: 'Acepto Televenta' },
        { i:  4, d: 'Pendiente' },
        { i:  5, d: 'Inconsistencia' },
        { i:  6, d: 'En Progreso' }
      ],
      frecuencia: null,
      frecuencias: [
        { i: '-1',   d: 'Todos' },
        { i:  'L', d: 'L' },
        { i:  'M', d: 'M' },
        { i:  'X',d: 'X' },
        { i:  'J',d: 'J' },
        { i:  'V',d: 'V' },
        { i:  'S',d: 'S' }
      ],
      gen: [
        { i:  0, d: 'NO' },
        { i:  1, d: 'SI' }
      ],
      catego: [
        { i:  1, d: 'Ecommerce' },
        { i:  3, d: 'Televenta' },
        { i:  4, d: 'Suscripcion SMS' },
        { i:  5, d: 'Programado' }
      ],
      dateTimePicker: [],
      desserts: [],
      headers: [
        { text: 'Acciones', value: 'acciones', width: 80, sortable: false },
        { text: 'ID', value: 'valCar_id', width: 50,  sortable: false },
        { text: 'Cartera ID', value: 'car_id', width: 100,  sortable: false },
        { text: 'Región', value: 'valCar_reg', width: 150,  sortable: false },
        { text: 'Gerencia', value: 'valCar_gerencia', width: 150,  sortable: false },
        { text: 'Cedis', value: 'valCar_cds', width: 150,  sortable: false },
        { text: 'Estatus validacion', value: 'valCar_val_status', width: 250,  sortable: false },
        { text: 'Modelo Cliente', value: 'valCar_mod_clnt', width: 150,  sortable: false },
        { text: 'Tipo Cliente', value: 'valCar_tipo_clnt', width: 70,  sortable: false },
        { text: 'Razon Pendiente', value: 'valCar_razon_baja', width: 200,  sortable: false, cellClass: 'truncate' },
        { text: 'Razon No Acepto Televenta', value: 'car_mot_inc', width: 70,  sortable: false },
        { text: 'Motivo No Acepto Televenta', value: 'car_tipo_mot_inc', width: 70,  sortable: false },
        { text: 'Horas de Atención', value: 'valCar_hour_call', width: 150,  sortable: false },
        { text: 'Alta', value: 'car_nuevo', width: 70,  sortable: false },
        { text: 'Intentos llamada', value: 'valCar_intentos', width: 70,  sortable: false },
        { text: 'Intento 1', value: 'intLlmd_int_1', width: 150,  sortable: false },
        { text: 'Intento 2', value: 'intLlmd_int_2', width: 150,  sortable: false },
        { text: 'Intento 3', value: 'intLlmd_int_3', width: 150,  sortable: false },
        { text: 'Intento 4', value: 'intLlmd_int_4', width: 150,  sortable: false },
        { text: 'Intento 5', value: 'intLlmd_int_5', width: 150,  sortable: false },
        { text: 'Intento 6', value: 'intLlmd_int_6', width: 150,  sortable: false },
        { text: 'Ruta', value: 'valCar_rt_prev', width: 70,  sortable: false },
        { text: 'Puertas Frío', value: 'valCar_pt_frio', width: 70,  sortable: false },
        { text: 'NUD', value: 'valCar_NUD'   , width: 50,  sortable: false },
        { text: 'Nombre', value: 'valCar_nombre', width: 200,  sortable: false },
        { text: 'Dirección', value: 'valCar_dir', width: 350,  sortable: false },
        { text: 'Num. Ext.', value: 'valCar_num_ext', width: 70,  sortable: false },
        { text: 'Num. Int.', value: 'valCar_num_int', width: 70,  sortable: false },
        { text: 'Colonia', value: 'valCar_col', width: 200,  sortable: false },
        { text: 'Calle 1', value:  'valCar_cll_1', width: 200,  sortable: false },
        { text: 'Calle 2', value: 'valCar_cll_2', width: 200,  sortable: false },
        { text: 'Manzana', value: 'car_manzana', width: 50,  sortable: false },
        { text: 'Lote', value: 'car_lote', width: 50,  sortable: false },
        { text: 'Local', value: 'car_local', width: 50,  sortable: false },
        { text: 'C.P.', value: 'valCar_cp', width: 50,  sortable: false },
        { text: 'Frecuencia', value: 'valCar_frec_vis', width: 70,  sortable: false },
        { text: 'Teléfono 1 Fijo', value: 'valCar_tel_mot', width: 50,  sortable: false },
        { text: 'Teléfono 2 Celular', value: 'valCar_tel_int', width: 50,  sortable: false },
        { text: 'Comentario', value: 'valCar_comments', width: 250,  sortable: false, cellClass: 'truncate' },
        { text: 'CallCenter Asignado', value: 'user_nombre_completo', width: 50,  sortable: false },
        { text: 'Correo', value: 'valCar_email', width: 50,  sortable: false },
        { text: 'Foto 1', value: 'valCar_f1', width: 150,  sortable: false, cellClass: 'truncate' },
        { text: 'GPS', value: 'valCar_gps', width: 200,  sortable: false },
        { text: 'Fecha Envio', value: 'valCar_upload_date', width: 120,  sortable: false },
        { text: 'Hora Envio', value: 'valCar_upload_time', width: 100,  sortable: false },
        { text: 'Update Massive', value: 'valCar_ms_updt', width: 50,  sortable: false }
      ],
      pageCount: 0,
      start: 0,
      total: 0,
      itemsT: 0,
      pagination: {
        page: 1,
        limit: 13,
        itemsPerPage: 13,
        currentPage: 1
      },
      dgFiltrar: false,
      idsFiltrar: null,
      clNuevo: {
        name: '',
        phone: '',
        phone2: '',
        folio: '',
        oper: 1,
        region: '',
        gerencia: '',
        cedis: '',
        ruta: ''
      },
      nuevoValid: true,
      nameRules: [
        v => !!v || 'Nombre Requerido'
      ],
      folioRules: [
        v => !!v || 'Folio Requerido'
      ],
      phoneRules: [
        v => (v && v.length >= 10) || 'Escriba un Número valido',
      ],
      dgCambios: false,
      dgAsignar: false,
      //nwRegion: '',
      itemsRegiones: [],
      itmRegion: null,
      itemsGerencias: [],
      itmGerencia: null,
      itemsCedis: [],
      itmCedis: null,
      itemsRutas: [],
      itmRuta: null,
      resultadoMasivo: '',
      callCenter: '',
      itemsCallCenter: [],
      masvisoFileXls: null,
      dgDetalle: false,
      currentClient: '',
      currentImagen: '',
      currentImagenFirma: '',
      currentImagenTicket: '',
      currentImagenCliente: '',
      permisos: {},
      nudsFiltrar: null,
      dgFiltrarNuds: false,
      idsReasignar: null,
      restantes: 0,
      dgPassword: false,
      pass: '',
      dgMostrar: null,
      titlePass: '',
      dgCallCenter: false,
      callCenterFileXls: null,
      dgAsignarReg: false,
      asignarRegDg: null,
      asigSuper: {},
      superRegiones: [],
      superGerencias: [],
      superCedis: [],
      restantesSuper: 0,
      dgComment: false,
      contComment: '',
      tlComment: '',
      clEditar: {},
      showEdit: false,
      editValid: null
    }
  },
  methods: {
    handleCommand(cm){
      switch (cm){
        case 'descarga':
          this.descargaFiltro()
        break
        case 'filtrar':
          this.idsFiltrar = null
          this.dgFiltrar = true
        break
        case 'filtrarNud':
          this.nudsFiltrar = null
          this.dgFiltrarNuds = true
        break
        case 'callCenter':
          this.dgPassword = true
          this.titlePass = 'Subir Call Center'
          this.dgMostrar = 'dgCallCenter'
        break
        case 'loadJson':
          
        break
        case 'asignarSuper':
          this.dgAsignarReg = true
          if(this.$refs.asignarReg){
            this.$refs.asignarReg.reset()
          }
          this.superGerencias = []
          this.superCedis = []
          this.asigSuper = {}
          this.getCallCenters()
          this.getRegiones(2)
        break
        case 'dowFotos':
          this.descargaFotos()
        break
        case 'cambiar':
          this.dgPassword = true
          this.titlePass = 'Cambios Masivos'
          this.dgMostrar = 'dgCambios'
          //this.dgCambios = true
        break
        case 'reAsigna':
          this.dgAsignar = true
          this.getCallCenters()
        break
      }
    },
    rowClass(item) {
      //console.log(item);
      if(item.valCar_metros_gps > 0 && item.valCar_metros_gps < 25) { return 'table-trans' }
      if(item.valCar_metros_gps > 25 && item.valCar_metros_gps < 75) { return 'table-alt' }
      if(item.valCar_metros_gps > 75 ) { return 'table-baj' }
      {
        return 'table-xxx'
      }
    },
    newPagina(p){
      // console.log(p);
      this.pagination.page = p
      this.start = this.pagination.limit * (p - 1)
      this.pagination.currentPage = p

      this.getData()
    },
    filtrar() {
      this.idsFiltrar = null
      this.nudsFiltrar = null
      this.pagination.page = 1
      this.start = 0
      //this.restantesReg(0)
      this.getData()
    },
    getData() {
      //console.log(this.pre.join(','))
      this.overlay = true
      let json ={
        c: "carteraFin",
        cmd: "getCartFinData",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        lvl: this.lvl,
        //ip: "189.167.28.167",
        opr: 1,
        page: this.pagination.page,
        start: this.start,
        limit: this.pagination.limit,
        //date_fr: this.dateTimePicker[0],
        //date_to: this.dateTimePicker[1]
      };

      if(this.idsFiltrar && this.idsFiltrar != ''){
        json.ids = this.idsFiltrar
      }else if(this.nudsFiltrar && this.nudsFiltrar != ''){
        json.nuds = this.nudsFiltrar
      }else{
        json.u_reg_id = this.region,
        json.ger_id = this.gerencia,
        json.cds_id = this.cedi,
        json.rta_id = this.pre ? this.pre.join(',') : '',
        json.sts_id = this.estatus ? this.estatus.join(',') : '',
        json.valid = this.validado,
        json.frecid = this.frecuencia ? this.frecuencia.join(',') : ''
        json.date_fr = this.dateTimePicker[0]
        json.date_to = this.dateTimePicker[1]
      }

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data);
          var resp = data.data.rows
          this.desserts = resp;
          this.pageCount = Math.ceil(data.data.results / this.pagination.limit)
          this.total = data.data.results
          this.itemsT = resp.length
          this.overlay = false
        //this.pagination.rowsTotal = data.data.results
        //this.isBusy = false
        this.restantesReg(0)
      })
    },
    getRegiones(sc){
      let json ={
        c: "geppValida",
        cmd: "getRegionesJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data);
        if(!data.data.success && typeof(data.data.success) != "undefined"){
            localStorage.clear()
            this.$router.push('login')
        }else{
          if(sc == 0){
            this.regiones = data.data
            //
            this.regiones.unshift({ i: -1, d: 'TODAS' })
            //this.itemsRegiones.splice(0,1)
          }else if(sc == 1){
            this.itemsRegiones = data.data
          }else if(sc == 2){
            this.superRegiones = data.data
          }
        }
      })
    },
    getGerencias(sc){

        this.gerencia = null
        this.cedi = null
        this.pre = null
        this.usuario = null

      if(this.region == -1){
        this.gerencias = []
        this.cedis = []
        this.preventas = []
        this.usuarios = []

        return false
      }

      let region = ''
      if(sc == 0){
        region = this.region
      }else if(sc == 1){
        region = this.itmRegion
      }else if(sc == 2){
        region = this.asigSuper.region
      }

      let json ={
        c: "geppValida",
        cmd: "getGerenciasJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        reg: region
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.gerencias = data.data
          this.gerencias.unshift({ i: -1, d: 'TODAS' })
        }else if(sc == 1){
          this.itemsGerencias = data.data
        }else if(sc == 2){
          this.superGerencias = data.data
        }
      })
    },
    getCedis(sc){

        this.cedi = null
        this.pre = null
        this.usuario = null
        this.preventas = []
        this.usuarios = []

      if(this.gerencia == -1){
        this.cedis = []
        
        return false
      }

      let gerencia = ''
      if(sc == 0){
        gerencia = this.gerencia
      }else if(sc == 1){
        gerencia = this.itmGerencia
      }else if(sc == 2){
        gerencia = this.asigSuper.gerencia
      }

      let json ={
        c: "geppValida",
        cmd: "getCedisJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        ger: gerencia
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.cedis = data.data
          this.cedis.unshift({ i: -1, d: 'TODOS' })
        }else if(sc == 1){
          this.itemsCedis = data.data
        }else if(sc == 2){
          this.superCedis = data.data
        }
      })
    },
    getPreventa(sc){

        this.pre = null
        this.usuario = null


      if(this.cedi == -1){
        //this.preventas = []
        this.usuarios = []
        
        return false
      }
      
      this.getUsuarios()
      
      let cedi = ''
      if(sc == 0){
        cedi = this.cedi
      }else if(sc == 1){
        cedi = this.itmCedis
      }

      let json ={
        c: "geppValida",
        cmd: "getRtPrevJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        cds: cedi
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc==0) { 
        this.preventas = data.data
        this.preventas.unshift({ i: '-1', d: 'TODAS' })
        }else if(sc == 1) {
          this.itemsRutas = data.data
        }
      })
    },
    getUsuarios(){
      let json ={
        c: "geppValida",
        cmd: "getUserJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        cds: this.cedi,
        date_fr: '2020-01-01',//this.dateTimePicker[0],
        date_to: this.dateTimePicker[1]
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        this.usuarios = data.data
        this.usuarios.unshift({ i: -1, d: 'TODOS' })
      })
    },
    descargaFiltro(){
      var str = ''
      str += this.region ? "&u_reg_id="+this.region : ''
      str += this.gerencia ? "&ger_id="+this.gerencia : ''
      str += this.cedi ? "&cds_id="+this.cedi : ''
      str += this.pre && this.pre != '' ? "&rta_id="+this.pre : ''
      str += this.estatus ? "&sts_id="+this.estatus : ''
      str += this.validado ? "&valid="+this.validado : ''
      str += this.frecuencia ? "&frecid="+this.frecuencia : ''
      str += this.dateTimePicker[0] ? "&date_fr="+this.dateTimePicker[0] : ''
      str += this.dateTimePicker[1] ? "&date_to="+this.dateTimePicker[1] : ''

      window.location = this.$api_url+"?c=carteraFin&cmd=getCartFinDataexportlist&exec=self&uid="+this.uid+"&hash="+this.hash+"&lvl="+this.lvl+"&opr=0"+str;
    },
    filtrarRegistros(){
      this.dgFiltrar = false
      this.pagination.page = 1
      this.start = 0
      this.getData()
    },
    filtrarRegistrosNuds(){
      this.dgFiltrarNuds = false
      this.pagination.page = 1
      this.start = 0
      this.getData()
    },
    reAsignar(){
      let json ={
        c: "CarteraFin",
        cmd: "_reAsignarCallCenter",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        idReasign: this.idsReasignar,
        CallId: this.callCenter
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        this.getData()
        this.dgReAsignar = false
          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
        
      })
    },
    cambiosMasivos(){

      const formData = new FormData();
      formData.append('wnd_upld_xls', this.masvisoFileXls);
      formData.append('wnd_upld_act', 2);
      formData.append('wnd_upld_uid', this.uid);

      axios.post(this.$api_url + "?c=CarteraFin&cmd=processForm&exec=self&hash="+this.hash+"&uid="+this.uid, formData)
      .then(data=>{
          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    subirCallCenter(){

      const formData = new FormData();
      formData.append('wnd_upld_xls', this.callCenterFileXls);
      formData.append('wnd_upld_act', 2);
      formData.append('wnd_upld_uid', this.uid);

      axios.post(this.$api_url + "?c=CarteraFin&cmd=processFormCallCenter&exec=self&hash="+this.hash+"&uid="+this.uid, formData)
      .then(data=>{
          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    masivoArchivo(file){
      this.masvisoFileXls = file.raw
    },
    callCenterArchivo(file){
      this.callCenterFileXls = file.raw
    },
    getCallCenters(){
      let json ={
        c: "CallCenterRefresco",
        cmd: "getCallCenterUsersJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };
     axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        this.itemsCallCenter = data.data
      })
    },
    eventRow(ev, rd){
      //console.log(rd.item);
      var record = rd.item
      this.currentClient = record.valCar_nombre
      this.dgDetalle = true
      this.currentImagen = "http://tms-tradicional-refresco.s3.amazonaws.com/"+record.valCar_f1
      var latlng = record.valCar_gps.split(',')
      var lat = parseFloat(latlng[0])
      var lng = parseFloat(latlng[1])
      this.loadMapa(lat, lng)
    },
    loadMapa(lat, lng){
    loader.load().then(function(google) {

      // Custom Skin & Settings Map Begin
      const myLatlng2 = new google.maps.LatLng(lat, lng); //(23.6, -102.5);
      const mapOptions2 = {
        zoom: 10,
        center: myLatlng2,
        scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
        disableDefaultUI: true, // a way to quickly hide all controls
        zoomControl: true
      };

      // console.log(document.getElementById("customSkinMap"));
      const map2 = new google.maps.Map(
        document.getElementById("customSkinMap"),
        mapOptions2
      );

      const marker2 = new google.maps.Marker({
        position: myLatlng2,
        title: "Custom Skin & Settings Map!"
      });

      marker2.setMap(map2);
      // Custom Skin & Settings Map End
    });
    },
    descargaFotos(){
      var str = ''
      str += this.region ? "&u_reg_id="+this.region : '0'
      str += this.gerencia ? "&ger_id="+this.gerencia : '0'
      str += this.cedi ? "&cds_id="+this.cedi : '0'
      str += this.pre && this.pre != '' ? "&rta_id="+this.pre : '0'
      str += this.estatus ? "&sts_id="+this.estatus : '0'
      str += this.validado ? "&valid="+this.validado : '0'
      str += this.frecuencia ? "&frecid="+this.frecuencia : '0'
      str += this.dateTimePicker[0] ? "&date_fr="+this.dateTimePicker[0] : ''
      str += this.dateTimePicker[1] ? "&date_to="+this.dateTimePicker[1] : ''
      
      window.location = this.$api_url+"?c=carteraFin&cmd=DownloadFotos&exec=self&uid="+this.uid+"&hash="+this.hash+"&lvl="+this.lvl+"&opr_id=0"+str+"&pId=0";
      
    },
    restantesReg(sc){
      //this.overlay = true
      let region = 0
      let gerencia = 0
      let cedi = 0
      let d1 = ''
      let d2 = ''
      if(sc == 0){
        region = this.region
        gerencia = this.gerencia
        cedi = this.cedi
        d1 = this.dateTimePicker[0]
        d2 = this.dateTimePicker[1]
      }else if(sc == 1){
        region = this.asigSuper.region ? this.asigSuper.region : 0
        gerencia = this.asigSuper.gerencia ? this.asigSuper.gerencia : 0
        cedi = this.asigSuper.cedi ? this.asigSuper.cedi : 0
        d1 = this.asigSuper.dateTime ? this.asigSuper.dateTime[0] : this.dateTimePicker[0]
        d2 = this.asigSuper.dateTime ? this.asigSuper.dateTime[1] : this.dateTimePicker[1]
      }

      let json ={
        c: "CallCenterRefresco",
        cmd: "_restantes",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        u_reg_id: region,
        ger_id: gerencia,
        cds_id: cedi,
        date_fr: d1,
        date_to: d2
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0) this.restantes = data.data
        if(sc == 1) this.restantesSuper = data.data
      })
    },
    asignarSuper(){
      this.overlay = true
      let json = {
        c: "CallCenterRefresco",
        cmd: "_asignarSupervisor",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        u_reg_id: this.asigSuper.region ? this.asigSuper.region : null,
        ger_id: this.asigSuper.gerencia ? this.asigSuper.gerencia : null,
        cds_id: this.asigSuper.cedi ? this.asigSuper.cedi : null,
        date_fr: this.asigSuper.dateTime[0],
        date_to: this.asigSuper.dateTime[1],
        numasig: this.asigSuper.asignar ? this.asigSuper.asignar : 0,
        CallId: this.asigSuper.callCenter
        //ids: this.idsFiltrar
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        this.overlay = false
            this.$notify({
              title: '',
              message: 'Se realizo la Asignación con éxito.',
              verticalAlign: 'top',
              horizontalAlign: 'center',
              type: 'success',
              timeout: 10000,
              icon: 'tim-icons icon-bell-55'
            });

            this.dgAsignarReg = false
            this.getData()

      })
    },
    eventCommentRow(item){
      console.log(item);
      this.dgComment = true
      this.tlComment = item.valCar_nombre
      this.contComment = item.valCar_comments
    },
    eventEditRow(itm){
      console.log(itm);
      this.clEditar = {}
      this.clEditar.valCarId = itm.valCar_id
      this.clEditar.intLlamadas = itm.valCar_intentos
      this.clEditar.nud = itm.valCar_NUD
      this.clEditar.modelo = itm.valCar_mod_clnt
      this.clEditar.tipo = itm.valCar_tipo_clnt
      this.clEditar.nombre = itm.valCar_nombre
      this.clEditar.telFijoU = itm.valCar_tel_mot
      this.clEditar.telCelD = itm.valCar_tel_int
      this.clEditar.telFijoT = itm.valCar_tel_3
      this.clEditar.telCelC = itm.valCar_tel_4
      this.clEditar.contactAd = itm.valCar_contacto_adicional
      this.clEditar.correo = itm.valCar_email
      this.clEditar.direccion = itm.valCar_dir
      this.clEditar.nExt = itm.valCar_num_ext
      this.clEditar.nInt = itm.valCar_num_int
      this.clEditar.colonia = itm.valCar_col
      this.clEditar.calle1 = itm.valCar_cll_1
      this.clEditar.calle2 = itm.valCar_cll_2
      this.clEditar.cp = itm.valCar_cp
      if(itm.valCar_val_status){
        this.clEditar.status = this.statusEdt.find(st => st.d.toLowerCase() == itm.valCar_val_status.toLowerCase()).i
      }
      if(itm.valCar_gepp_rev){
        this.clEditar.valido = this.valEdt.find(v => v.d.toLowerCase() === itm.valCar_gepp_rev.toLowerCase()).i
      }
      if(itm.valCar_suscripcion){
        this.clEditar.categoria = this.catego.find(c => c.d.toLowerCase() === itm.valCar_suscripcion.toLowerCase()).i
      }

      this.showEdit = true
    },
    validateAction(){
      if(this.pass == 'admin#'){
        this.dgPassword = false
        this.pass = ''
        if(this.dgMostrar == 'dgCallCenter') this.dgCallCenter = true
        if(this.dgMostrar == 'dgCambios') this.dgCambios = true
      }else{
        this.$notify({
            title: 'Error',
            message: 'Contraseña incorrecta, verifica e intenta de nuevo.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      }
    },
    actualizarCliente(){

      this.overlay = true
      let json = {
        c: "carteraFin",
        cmd: "sumbitChanges",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        vid: this.clEditar.valCarId,
        intentos: this.clEditar.intLlamadas,
        chn: JSON.stringify(this.getAllDataEdt())
      };
      

      const queryString = new URLSearchParams(json).toString();

      axios.post(this.$api_url+'?'+queryString)
      .then(data=>{
        //console.log(data.data);
        this.overlay = false
        this.showEdit = false
          this.$notify({
            title: '',
            message: 'Se actualizó el registro con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });

          if(this.pagination.currentPage != 1){
            this.pagination.page = 1
            this.pagination.currentPage = 1
            this.start = 0
          }

        this.getData()
        
      })

    },
    getAllDataEdt(){
      return {
          wnd_edt_nud: this.clEditar.nud ? this.clEditar.nud : '',
          wnd_edt_modelo: this.clEditar.modelo ?  this.clEditar.modelo : '',
          wnd_edt_tipo: this.clEditar.tipo ?  this.clEditar.tipo : '',
          wnd_edt_nme: this.clEditar.nombre ?  this.clEditar.nombre : '',
          wnd_edt_tel: this.clEditar.telFijoU ?  this.clEditar.telFijoU : '',
          wnd_edt_tel_adi: this.clEditar.telCelD ?  this.clEditar.telCelD : '',
          wnd_edt_tel_3: this.clEditar.telFijoT ?  this.clEditar.telFijoT : '',
          wnd_edt_tel_4: this.clEditar.telCelC ?  this.clEditar.telCelC : '',
          wnd_edt_adiCon: this.clEditar.contactAd ?  this.clEditar.contactAd : '',
          wnd_edt_corrV: this.clEditar.correo ?  this.clEditar.correo : '',
          wnd_edt_dir: this.clEditar.direccion ?  this.clEditar.direccion : '',
          wnd_edt_next: this.clEditar.nExt ?  this.clEditar.nExt : '',
          wnd_edt_nint: this.clEditar.nInt ?  this.clEditar.nInt : '',
          valCar_col: this.clEditar.colonia ?  this.clEditar.colonia : '',
          wnd_edt_cll1: this.clEditar.calle1 ?  this.clEditar.calle1 : '',
          wnd_edt_cll2: this.clEditar.calle2 ?  this.clEditar.calle2 : '',
          wnd_edt_cp: this.clEditar.cp ?  this.clEditar.cp: '',
          wnd_status_sus: this.clEditar.status ?  this.clEditar.status : '',
          wnd_status_VAL: this.clEditar.valido ?  this.clEditar.valido : '',
          wnd_status_cat: this.clEditar.categoria ?  this.clEditar.categoria : '',
          wnd_edt_id: this.clEditar.categoria ?  this.clEditar.categoria : ''
      }
    },
   cleanNumberEdt(id, val){
      //return false
      var tmpId = id.split('-')[1]
       val = val.replace(/[^0-9]/g,'')
       this.clEditar[tmpId] = val
      //this.validaNumero(id, val)
     
    },
  },
  mounted () {
    if(localStorage.getItem('key')) {
      this.hash = localStorage.getItem('key')
      this.uid = localStorage.getItem('uid')
      this.lvl = localStorage.getItem('level')

      this.permisos = JSON.parse(localStorage.getItem('permission')).cartera_fin.actions

      var dat = new Date();
      var mo = (dat.getMonth() + 1) < 10 ? '0' + (dat.getMonth() + 1) : (dat.getMonth() + 1)
      var da = dat.getDate() < 10 ? '0' + dat.getDate() : dat.getDate()
      this.dateTimePicker[0] = dat.getFullYear() + '-' + mo + '-' + da
      this.dateTimePicker[1] = dat.getFullYear() + '-' + mo + '-' + da

      this.getRegiones(0)
      this.getData()
    } else {
       this.$router.push('login');
    }
  }
}
</script>
<style>
.v-btn {
  max-height: 20px;
  text-align: center;
}

.v-btn__content {
   font-size: 10px;
}

/*
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
   text-align: left;
   background-color: rgba(0, 0, 0, .05);
}
*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 11px !important;
  /* height: 22px;*/
}
/*
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}*/

.theme--light.v-pagination .v-pagination__item--active {
  background-color: #124A98;
  color: #fff;
}

  .map {
    height: 350px;
    width: 100%;
  }

.table-trans {
  background-color: #85C1E9;
}
.table-alt {
  background-color: #45B39D;
}
.table-baj {
  background-color: #F78181;
}
.table-pen {
  background-color: #C8A2C8;
}
.table-inco {
  background-color: #efef4c;
}
.table-pro {
  background-color: #C8A2C8;
}
.table-xxx {
  background-color: #fff;
}

.truncate {
  max-width: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>