<template>
  <div class="content">
    <div class="row">
      <div class="col-2">
          <el-dropdown style="width:100%" @command="handleCommand">
            <el-button type="primary" size="mini" style="width:100%">
              Acciones<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="filtrarNud">Buscar NUD</el-dropdown-item>
              <el-dropdown-item command="asignarSuper">Asignar Supervisor</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        Regiones:
          <el-select v-model="region" placeholder="REGIONES" size="mini" @change="getGerencias(0)">
            <el-option
              v-for="item in regiones"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
        <span v-if="gerencias.length != 0">Gerencias:</span>
          <el-select v-if="gerencias.length != 0" v-model="gerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(0)">
            <el-option
              v-for="item in gerencias"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="cedis.length != 0">Cedis:</span>
          <el-select v-if="cedis.length != 0" v-model="cedi" placeholder="CEDIS" size="mini" @change="getPreventa(0)">
            <el-option
              v-for="item in cedis"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="preventas.length != 0">Rutas:</span>
          <el-select v-if="preventas.length != 0" v-model="pre" placeholder="RUTAS" size="mini" multiple collapse-tags>
            <el-option
              v-for="item in preventas"
              :key="item.i+'pr'"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Estatus:
          <el-select v-model="estatus" placeholder="ESTATUS" size="mini" collapse-tags>
            <el-option
              v-for="item in status"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Modelo:
          <el-select v-model="modelo" placeholder="MODELO" size="mini" collapse-tags>
            <el-option
              v-for="item in modelos"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Tipo:
          <el-select v-model="tipo" placeholder="FRECUENCIA" size="mini" multiple collapse-tags>
            <el-option
              v-for="item in tipos"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Fechas:
            <el-date-picker
              type="daterange"
              placeholder="Date Time Picker"
              v-model="dateTimePicker"
               size="mini"
               format="dd-MM-yyyy"
               value-format="yyyy-MM-dd"
               prefix-icon="false"
               class="el-input__icon"
               range-separator="A"
               style="font-size:8px; padding:3px 0px 3px 6px;"
               
            >
            </el-date-picker>
          <el-button size="mini" type="primary" class="mt-3" style="width:100%"
          @click="filtrar"
          >
            Filtrar
          </el-button>
        </div>
      <div class="col-10">
          <div>Registros por Asignar: <span><b>{{restantes}}</b></span></div>
          <v-data-table
            dense
            :headers="headers"
            :items="desserts"
            item-key="valCar_id"
            :item-class="rowClass"
            :options="pagination"
            hide-default-footer
            @dblclick:row="eventEditRow"
          >
          <template v-slot:item.valCar_val_status="{ item }" >
              {{ item.valCar_val_status == 1 ? 'Alta': item.valCar_val_status == 2 ? 'Baja' : item.valCar_val_status == 3 ? 'Transformado' : item.valCar_val_status == 4 ? 'Pendiente' : item.valCar_val_status == 5 ? 'Inconsistencia' : item.valCar_val_status == 6 ? 'En Progreso' : ''}}
          </template>
          </v-data-table>
          <div
            slot="footer"
            class="col-12 my-2 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Registros <b>{{ start + 1 }}</b> A <b>{{ start + itemsT }}</b> de <b>{{ total }}</b>
              </p>
            </div>
            <b-pagination
              v-model="pagination.currentPage"
              :per-page="pagination.limit"
              :total-rows="total"
              size="sm"
              @change="newPagina"
              ></b-pagination>
          </div>
          <!--<div class="row pt-2 justify-content-between">
            <div class="col-4">
              Registros de {{ start + 1 }} A {{ start + itemsT }} de {{ total }}
            </div>
            <div class="col-6">
              <v-pagination
                v-model="pagination.page"
                :length="pageCount"
                :total-visible="7"
                @input="newPagina($event)"
              ></v-pagination>
            </div>
          </div>-->
      </div>
    </div>

    <!-- modals-->


    <el-dialog
      title="Filtrar Registros NUD's"
      :visible.sync="dgFiltrarNuds"
      width="30%">
      <p>Ingrese el NUD</p>
      <el-input
        type="text"
        placeholder="NUD"
        v-model="nudsFiltrar">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dgFiltrarNuds = false">Cancelar</el-button>
        <el-button type="primary" @click="filtrarRegistrosNuds">Filtrar</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="Asignar Registros"
      :visible.sync="dgAsignarReg"
      width="35%">
      <div data-app>
          <v-form ref="asignarReg" v-model="asignarRegDg" lazy-validation >
          <div class="row no-gutters">
            <div class="col-6 pr-2">
              Región:
                <el-select v-model="asigSuper.region" placeholder="REGIONES" size="mini" @change="getGerencias(2)">
                  <el-option
                    v-for="item in superRegiones"
                    :key="item.i"
                    :label="item.d"
                    :value="item.i">
                  </el-option>
                </el-select>
            </div>
            <div class="col-6 pl-2">
              Gerencia:
              <el-select :disabled="superGerencias.length == 0" v-model="asigSuper.gerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(2)">
                <el-option
                  v-for="item in superGerencias"
                  :key="item.i"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-6 pr-2">
              Cedi:
              <el-select :disabled="superCedis.length == 0" v-model="asigSuper.cedi" placeholder="CEDIS" size="mini" >
                <el-option
                  v-for="item in superCedis"
                  :key="item.i"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>
            </div>
            <div class="col-6 pl-2">
              <span>CAS:</span>
              <el-select v-model="asigSuper.callCenter" placeholder="Usuarios" size="mini" @change="restantesReg(1)">
                <el-option
                  v-for="item in itemsCallCenter"
                  :key="item.i+'us'"
                  :label="item.d"
                  :value="item.i">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-12">
              Fechas:
              <el-date-picker
                type="daterange"
                placeholder="Date Time Picker"
                v-model="asigSuper.dateTime"
                size="mini"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                prefix-icon="false"
                class="el-input__icon"
                range-separator="A"
                style="font-size:8px; padding:3px 0px 3px 6px;"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-6 pr-2">
              <br/>
              Por Asignar: <b><span style="font-size:20px"> {{ restantesSuper }} </span></b>
            </div>
            <div class="col-6 pl-2">
              <span>Asignar:</span>
                <el-input
                  type="number"
                  placeholder=""
                  v-model="asigSuper.asignar"
                  size="mini"
                  min="0"
                  onkeyup="value=value.replace(/[^\d]/g,0)"
                  >
                </el-input>
            </div>
          </div>
              <div class="row">
                <div class="col-md-12 mt-3 text-center">
                  <el-button type="primary" @click="asignarSuper" :disabled="asigSuper.asignar == null || asigSuper.asignar == 0" >Asignar Registros</el-button>
                </div>
              </div>
      </v-form>
      </div>
    </el-dialog>


    <!-- EDITAR CLIENTE -->
    <el-dialog
      :visible.sync="showEdit"
      title="Editar Cliente"
      width="50%"
      top="20px">
      <div data-app>
        <v-form ref="editClient" v-model="editValid" lazy-validation >
          <div class="row no-gutters">
            <div class="col-4" style="font-size:14px">
              <b>Nud Cliente: </b>{{ clEditar.nud }}
            </div>
              <div class="col-4 text-left" style="font-size:14px">  
                <b>Frecuencia de Llamada: </b>{{ clEditar.frecuencia }}
              </div>
          </div>
          <div class="row">
              <div class="col-8 pr-2">
                <v-text-field dense
                  id="cl_edit-nombre"
                  v-model="clEditar.nombre"
                  style="font-size:11px"
                  :rules="nameRules" label="Nombre" required
                  @blur="v => cleanStringEdt(v.target.id, v.target.value)"
                  ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-4 pr-2">
                <v-text-field dense
                  v-model="clEditar.telFijoU"
                  id="cl_edit-telFijoU"
                  style="font-size:11px"
                  :counter="10"
                  maxlength="10"
                  @blur="v => cleanNumberEdt(v.target.id, v.target.value)"
                  label="Teléfono 1 fijo"  ></v-text-field>
              </div>
              <div class="col-4 pr-2">
                <v-text-field dense
                  v-model="clEditar.telCelD"
                  id="cl_edit-telCelD"
                  style="font-size:11px"
                  :counter="10"
                  maxlength="10"
                  label="Teléfono 2 celular"
                  @blur="v => cleanNumberEdt(v.target.id, v.target.value)"
                  ></v-text-field>
              </div>
              <div class="col-4 pr-2">
                <v-text-field dense
                  v-model="clEditar.correo"
                  style="font-size:11px"
                  label="Correo"  ></v-text-field>
              </div>
          </div>
            <div class="row no-gutters">
              <div class="col-8 pr-2">
                <v-text-field dense
                  v-model="clEditar.direccion"
                  id="cl_edit-direccion"
                  style="font-size:11px"
                  label="Dirección" required ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clEditar.nExt"
                  style="font-size:11px"
                  :rules="[v => !!v || 'Escriba Número']"
                  label="Num. Exterior" required ></v-text-field>
              </div>
              <div class="col-2 pr-2">
                <v-text-field dense
                  v-model="clEditar.nInt"
                  style="font-size:11px"
                  label="Num. Interior" ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-3 pr-2">
                <v-text-field dense
                  v-model="clEditar.colonia"
                  id="cl_nuevo-colonia"
                  style="font-size:11px"
                  label="Colonia" required ></v-text-field>
              </div>
              <div class="col-4 pr-2">
                <v-text-field dense
                  v-model="clEditar.calle1"
                  id="cl_edit-calle1"
                  style="font-size:11px"
                  label="Calle 1" required ></v-text-field>
              </div>
              <div class="col-4 pr-2">
                <v-text-field dense
                  v-model="clEditar.calle2"
                  id="cl_edit-calle2"
                  style="font-size:11px"
                  label="Calle 2" required ></v-text-field>
              </div>
              <div class="col-1 pr-2">
                <v-text-field dense
                  v-model="clEditar.cp"
                  id="cl_edit-cp"
                  style="font-size:11px"
                  @blur="v => cleanNumberEdt(v.target.id, v.target.value)"
                  label="CP" required ></v-text-field>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-8 pr-2">
                <v-text-field dense
                  v-model="clEditar.comentario"
                  style="font-size:11px"
                  @blur="v => cleanNumberEdt(v.target.id, v.target.value)"
                  label="Comentario" required ></v-text-field>
              </div>

              <div class="col-4 pr-2">
                  <v-radio-group v-model="radioGroup" @change="valCheck">
                    <v-radio
                      label="Acepta Televenta"
                      value="0"
                    ></v-radio>
                    <v-radio
                      label="No Acepta Televenta"
                      value="1"
                    ></v-radio>
                  </v-radio-group>
              </div>
          </div>
          <div class="row no-gutters">
              <div class="col-3 pr-2">
                <v-select v-model="clEditar.status" :items="statusEdt"
                    label="Estatus"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                    @change="valStatus"
                  ></v-select>
              </div>

              <div class="col-3 pr-2">
                <v-select v-model="clEditar.razon" :items="inconsistencias"
                    label="Razón"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                    @change="getMotivos"
                    :disabled="clEditar.status !== 5"
                  ></v-select>
              </div>
              <div class="col-3 pr-2">
                <v-select v-model="clEditar.motivo" :items="motivos"
                    label="Motivo"
                    item-value="i"
                    item-text="d"
                    style="font-size:11px"
                    required
                    dense
                    :disabled="motivos.length == 0"
                  ></v-select>
              </div>
          </div>
              <div class="row">
                <div class="col-md-12 mt-3 text-center">
                  <el-button type="primary" @click="actualizarCliente">Actualizar Información</el-button>
                </div>
              </div>
            </v-form>
      </div>
    </el-dialog>
    <!-- END EDITAR CLIENT -->
    <!-- Dialog Contraseña -->

    <!---->
    <!-- end modals-->
    <v-overlay :value="overlay" z-index="2002">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { TimeSelect,
          DatePicker,
          Select,
          Option,
          Button,
          Dropdown, DropdownMenu, DropdownItem, Switch, Upload, Dialog, FormItem, Form } from 'element-ui';
import axios from 'axios';

import { Loader, LoaderOptions } from "google-maps";
const loader = new Loader("AIzaSyDnsLdCKdxoFbJMxVfZHMDnr_SB-L-T7nA");

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [FormItem.name]: FormItem,
    [Form.name]: Form
  },
  data() {
    return {
      overlay: false,
      uid: null,
      hash: null,
      lvl: null,
      loading: true,
      region: null,
      regiones: [
      ],
      gerencia: null,
      gerencias: [
      ],
      cedi: null,
      cedis: [
      ],
      usuario: null,
      usuarios: [],
      pre: null,
      preventas: [
        ],
      nuevos: [
      { i: -1, d: 'Todos' },
      { i:  0, d: 'Cartera' },
      { i:  1, d: 'Nuevos' }
        ],
      modelo: null,
      modelos: [],
      valEdt: [
        { i:  0, d: 'No Acepto' },
        { i:  1, d: 'Acepto Televenta' }
      ],
      estatus: null,
      status: [
        { i: -1, d: 'Todos' },
        { i: 1, d: 'Alta' },
        { i: 2, d: 'Baja' },
        { i: 3, d: 'Transformado' },
        { i: 4, d: 'Pendiente' },
        { i: 5, d: 'Inconsistencia' },
        { i: 6, d: 'En Progreso' },
        { i: 7, d: 'Programado' },
        { i: 8, d: 'Pendiente-Transformado' }
      ],
      statusEdt: [
        { i:  6, d: 'Progreso' },
        { i:  5, d: 'Inconsistencia' },
        //{ i:  1, d: 'Validado' },
      ],
      tipo: null,
      tipos: [],
      inconsistencias: [],
      motivos: [],
      dateTimePicker: [],
      desserts: [],
      headers: [
        { text: 'Acciones', value: 'acciones', width: 80, sortable: false },
        { text: 'ID', value: 'valCar_id', width: 50,  sortable: false },
        { text: 'Cartera ID', value: 'car_id', width: 100,  sortable: false },
        { text: 'Estatus validacion', value: 'valCar_val_status', width: 250,  sortable: false },
        { text: 'Modelo Cliente', value: 'car_mod_clnt', width: 150,  sortable: false },
        { text: 'Tipo Cliente', value: 'car_tipo_clnt', width: 70,  sortable: false },
        { text: 'Horas de Atención', value: 'valCar_hour_call', width: 150,  sortable: false },
        { text: 'Intentos llamada', value: 'valCar_intentos', width: 70,  sortable: false },
        { text: 'Intento 1', value: 'intLlmd_int_1', width: 150,  sortable: false },
        { text: 'Intento 2', value: 'intLlmd_int_2', width: 150,  sortable: false },
        { text: 'Intento 3', value: 'intLlmd_int_3', width: 150,  sortable: false },
        { text: 'Intento 4', value: 'intLlmd_int_4', width: 150,  sortable: false },
        { text: 'Intento 5', value: 'intLlmd_int_5', width: 150,  sortable: false },
        { text: 'Intento 6', value: 'intLlmd_int_6', width: 150,  sortable: false },
        { text: 'Días Espera', value: 'valCar_val_days', width: 150,  sortable: false },
        { text: 'Razon No Acepto Televenta', value: 'car_mot_inc', width: 70,  sortable: false },
        { text: 'Motivo No Acepto Televenta', value: 'car_tipo_mot_inc', width: 70,  sortable: false },
        { text: 'Correo', value: 'valCar_email', width: 50,  sortable: false },
        { text: 'Comentario', value: 'valCar_comments', width: 250,  sortable: false, cellClass: 'truncate' },
        { text: 'Nombre', value: 'valCar_nombre', width: 200,  sortable: false },
        { text: 'Dirección', value: 'valCar_dir', width: 350,  sortable: false },
        { text: 'Teléfono 1 Fijo', value: 'valCar_tel_mot', width: 50,  sortable: false },
        { text: 'Teléfono 2 Celular', value: 'valCar_tel_int', width: 50,  sortable: false },
        { text: 'NUD', value: 'valCar_NUD'   , width: 50,  sortable: false },
        { text: 'Ruta Preventa', value: 'valCar_rt_prev', width: 70,  sortable: false },
        { text: 'Frecuencia', value: 'valCar_frec_vis', width: 70,  sortable: false },
        { text: 'Calle 1', value:  'valCar_cll_1', width: 200,  sortable: false },
        { text: 'Calle 2', value: 'valCar_cll_2', width: 200,  sortable: false },
        { text: 'Num. Ext.', value: 'valCar_num_ext', width: 70,  sortable: false },
        { text: 'Num. Int.', value: 'valCar_num_int', width: 70,  sortable: false },
        { text: 'C.P.', value: 'valCar_cp', width: 50,  sortable: false },
        { text: 'Operacion', value: 'valCar_oper', width: 50,  sortable: false },
        { text: 'Región', value: 'valCar_reg', width: 50,  sortable: false },
        { text: 'Gerencia', value: 'valCar_gerencia', width: 50,  sortable: false },
        { text: 'Cedis', value: 'valCar_cds', width: 50,  sortable: false },
        { text: 'Resulta Incon', value: 'valCar_validado_incon', width: 50,  sortable: false },
        { text: 'ID Ref', value: 'valCar_id_refresco', width: 150,  sortable: false, cellClass: 'truncate' },
        { text: 'ID carRef', value: 'car_id_refresco', width: 200,  sortable: false },
        { text: 'Nuevo', value: 'car_nuevo', width: 120,  sortable: false }
      ],
      pageCount: 0,
      start: 0,
      total: 0,
      itemsT: 0,
      pagination: {
        page: 1,
        limit: 13,
        itemsPerPage: 13,
        currentPage: 1
      },
      dgFiltrar: false,
      idsFiltrar: null,
      clNuevo: {
        name: '',
        phone: '',
        phone2: '',
        folio: '',
        oper: 1,
        region: '',
        gerencia: '',
        cedis: '',
        ruta: ''
      },
      nuevoValid: true,
      nameRules: [
        v => !!v || 'Nombre Requerido'
      ],
      folioRules: [
        v => !!v || 'Folio Requerido'
      ],
      phoneRules: [
        v => (v && v.length >= 10) || 'Escriba un Número valido',
      ],
      dgCambios: false,
      dgAsignar: false,
      //nwRegion: '',
      itemsRegiones: [],
      itmRegion: null,
      itemsGerencias: [],
      itmGerencia: null,
      itemsCedis: [],
      itmCedis: null,
      itemsRutas: [],
      itmRuta: null,
      resultadoMasivo: '',
      callCenter: '',
      itemsCallCenter: [],
      masvisoFileXls: null,
      dgDetalle: false,
      currentClient: '',
      currentImagen: '',
      currentImagenFirma: '',
      currentImagenTicket: '',
      currentImagenCliente: '',
      permisos: {},
      nudsFiltrar: null,
      dgFiltrarNuds: false,
      idsReasignar: null,
      restantes: 0,
      dgPassword: false,
      pass: '',
      dgMostrar: null,
      titlePass: '',
      dgCallCenter: false,
      callCenterFileXls: null,
      dgAsignarReg: false,
      asignarRegDg: null,
      asigSuper: {},
      superRegiones: [],
      superGerencias: [],
      superCedis: [],
      restantesSuper: 0,
      dgComment: false,
      contComment: '',
      tlComment: '',
      clEditar: {},
      showEdit: false,
      editValid: null,
      radioGroup: "1"
    }
  },
  methods: {
    handleCommand(cm){
      switch (cm){
        case 'descarga':
          this.descargaFiltro()
        break
        case 'filtrar':
          this.idsFiltrar = null
          this.dgFiltrar = true
        break
        case 'filtrarNud':
          this.nudsFiltrar = null
          this.dgFiltrarNuds = true
        break
        case 'callCenter':
          this.dgPassword = true
          this.titlePass = 'Subir Call Center'
          this.dgMostrar = 'dgCallCenter'
        break
        case 'loadJson':
          
        break
        case 'asignarSuper':
          this.dgAsignarReg = true
          if(this.$refs.asignarReg){
            this.$refs.asignarReg.reset()
          }
          this.superGerencias = []
          this.superCedis = []
          this.asigSuper = {}
          this.getCallCenters()
          this.getRegiones(2)
        break
        case 'dowFotos':
          this.descargaFotos()
        break
        case 'cambiar':
          this.dgPassword = true
          this.titlePass = 'Cambios Masivos'
          this.dgMostrar = 'dgCambios'
          //this.dgCambios = true
        break
        case 'reAsigna':
          this.dgAsignar = true
          this.getCallCenters()
        break
      }
    },
    rowClass(item) {
      //console.log(item);
      if(item.valCar_metros_gps > 0 && item.valCar_metros_gps < 25) { return 'table-trans' }
      if(item.valCar_metros_gps > 25 && item.valCar_metros_gps < 75) { return 'table-alt' }
      if(item.valCar_metros_gps > 75 ) { return 'table-baj' }
      {
        return 'table-xxx'
      }
    },
    newPagina(p){
      // console.log(p);
      this.pagination.page = p
      this.start = this.pagination.limit * (p - 1)
      this.pagination.currentPage = p

      this.getData()
    },
    filtrar() {
      this.idsFiltrar = null
      this.nudsFiltrar = null
      this.pagination.page = 1
      this.start = 0
      //this.restantesReg(0)
      this.getData()
    },
    getData() {
      //console.log(this.pre.join(','))
      this.overlay = true
      let json ={
        c: "CallCenterRefresco",
        cmd: "getCallCenterData",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        lvl: this.lvl,
        pen: 1,
        opr: 1,
        page: this.pagination.page,
        start: this.start,
        limit: this.pagination.limit,
        //date_fr: this.dateTimePicker[0],
        //date_to: this.dateTimePicker[1]
      };

      if(this.nudsFiltrar && this.nudsFiltrar != ''){
        json.nud = this.nudsFiltrar
      }else{
        json.u_reg_id = this.region,
        json.ger_id = this.gerencia,
        json.cds_id = this.cedi,
        json.rta_id = this.pre ? this.pre.join(',') : '',
        json.sts_id = this.estatus,
        json.mod_id = this.modelo,
        json.typ_id = this.tipo ? this.tipo.join(',') : ''
        json.date_fr = this.dateTimePicker[0]
        json.date_to = this.dateTimePicker[1]
      }

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data);
          var resp = data.data.rows
          this.desserts = resp;
          this.pageCount = Math.ceil(data.data.results / this.pagination.limit)
          this.total = data.data.results
          this.itemsT = resp.length
          this.overlay = false
        //this.pagination.rowsTotal = data.data.results
        //this.isBusy = false
        this.restantesReg(0)
      })
    },
    getRegiones(sc){
      let json ={
        c: "geppValida",
        cmd: "getRegionesJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data);
        if(!data.data.success && typeof(data.data.success) != "undefined"){
            localStorage.clear()
            this.$router.push('login')
        }else{
          if(sc == 0){
            this.regiones = data.data
            //
            this.regiones.unshift({ i: -1, d: 'TODAS' })
            //this.itemsRegiones.splice(0,1)
          }else if(sc == 1){
            this.itemsRegiones = data.data
          }else if(sc == 2){
            this.superRegiones = data.data
          }
        }
      })
    },
    getGerencias(sc){

        this.gerencia = null
        this.cedi = null
        this.pre = null
        this.usuario = null

      if(this.region == -1){
        this.gerencias = []
        this.cedis = []
        this.preventas = []
        this.usuarios = []

        return false
      }

      let region = ''
      if(sc == 0){
        region = this.region
      }else if(sc == 1){
        region = this.itmRegion
      }else if(sc == 2){
        region = this.asigSuper.region
      }

      let json ={
        c: "geppValida",
        cmd: "getGerenciasJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        reg: region
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.gerencias = data.data
          this.gerencias.unshift({ i: -1, d: 'TODAS' })
        }else if(sc == 1){
          this.itemsGerencias = data.data
        }else if(sc == 2){
          this.superGerencias = data.data
        }
      })
    },
    getCedis(sc){

        this.cedi = null
        this.pre = null
        this.usuario = null
        this.preventas = []
        this.usuarios = []

      if(this.gerencia == -1){
        this.cedis = []
        
        return false
      }

      let gerencia = ''
      if(sc == 0){
        gerencia = this.gerencia
      }else if(sc == 1){
        gerencia = this.itmGerencia
      }else if(sc == 2){
        gerencia = this.asigSuper.gerencia
      }

      let json ={
        c: "geppValida",
        cmd: "getCedisJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        ger: gerencia
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.cedis = data.data
          this.cedis.unshift({ i: -1, d: 'TODOS' })
        }else if(sc == 1){
          this.itemsCedis = data.data
        }else if(sc == 2){
          this.superCedis = data.data
        }
      })
    },
    getPreventa(sc){

        this.pre = null

      if(this.cedi == -1){
        //this.preventas = []

        return false
      }
        
      let cedi = ''
      if(sc == 0){
        cedi = this.cedi
      }else if(sc == 1){
        cedi = this.itmCedis
      }

      let json ={
        c: "geppValida",
        cmd: "getRtPrevJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        cds: cedi
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc==0) { 
        this.preventas = data.data
        this.preventas.unshift({ i: '-1', d: 'TODAS' })
        }else if(sc == 1) {
          this.itemsRutas = data.data
        }
      })
    },
    descargaFiltro(){
      var str = ''
      str += this.region ? "&u_reg_id="+this.region : ''
      str += this.gerencia ? "&ger_id="+this.gerencia : ''
      str += this.cedi ? "&cds_id="+this.cedi : ''
      str += this.pre && this.pre != '' ? "&rta_id="+this.pre : ''
      str += this.estatus ? "&sts_id="+this.estatus : ''
      str += this.modelo ? "&valid="+this.modelo : ''
      str += this.tipo ? "&frecid="+this.tipo : ''
      str += this.dateTimePicker[0] ? "&date_fr="+this.dateTimePicker[0] : ''
      str += this.dateTimePicker[1] ? "&date_to="+this.dateTimePicker[1] : ''

      window.location = this.$api_url+"?c=carteraFin&cmd=getCartFinDataexportlist&exec=self&uid="+this.uid+"&hash="+this.hash+"&lvl="+this.lvl+"&opr=0"+str;
    },
    filtrarRegistros(){
      this.dgFiltrar = false
      this.pagination.page = 1
      this.start = 0
      this.getData()
    },
    filtrarRegistrosNuds(){
      this.dgFiltrarNuds = false
      this.pagination.page = 1
      this.start = 0
      this.getData()
    },
    reAsignar(){
      let json ={
        c: "CarteraFin",
        cmd: "_reAsignarCallCenter",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        idReasign: this.idsReasignar,
        CallId: this.callCenter
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        this.getData()
        this.dgReAsignar = false
          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
        
      })
    },
    cambiosMasivos(){

      const formData = new FormData();
      formData.append('wnd_upld_xls', this.masvisoFileXls);
      formData.append('wnd_upld_act', 2);
      formData.append('wnd_upld_uid', this.uid);

      axios.post(this.$api_url + "?c=CarteraFin&cmd=processForm&exec=self&hash="+this.hash+"&uid="+this.uid, formData)
      .then(data=>{
          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    subirCallCenter(){

      const formData = new FormData();
      formData.append('wnd_upld_xls', this.callCenterFileXls);
      formData.append('wnd_upld_act', 2);
      formData.append('wnd_upld_uid', this.uid);

      axios.post(this.$api_url + "?c=CarteraFin&cmd=processFormCallCenter&exec=self&hash="+this.hash+"&uid="+this.uid, formData)
      .then(data=>{
          this.$notify({
            title: '',
            message: 'Se realizaron los cambios con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 4000,
            icon: 'tim-icons icon-bell-55'
          });
      })
    },
    masivoArchivo(file){
      this.masvisoFileXls = file.raw
    },
    callCenterArchivo(file){
      this.callCenterFileXls = file.raw
    },
    getCallCenters(){
      let json ={
        c: "CallCenterRefresco",
        cmd: "getCallCenterUsersJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };
     axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        this.itemsCallCenter = data.data
      })
    },
    getModels(){
      let json ={
        c: "geppValida",
        cmd: "getModelosClienteJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };
     axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        this.modelos = data.data
        this.modelos.unshift({ i: -1, d: 'TODOS' })
      })
    },
    getTypes(){
      let json ={
        c: "geppValida",
        cmd: "getTiposClienteJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };
     axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        this.tipos = data.data
        this.tipos.unshift({ i: -1, d: 'TODOS' })
      })
    },
    getIncon(){
      let json ={
        c: "geppValida",
        cmd: "getTelIncJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };
     axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        this.inconsistencias = data.data
      })
    },
    getMotivos(){
      let json ={
        c: "geppValida",
        cmd: "getTelMotIncJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        id_inc: this.clEditar.razon
      };
     axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        this.motivos = data.data
      })
    },
    restantesReg(sc){
      //this.overlay = true
      let region = 0
      let gerencia = 0
      let cedi = 0
      let d1 = ''
      let d2 = ''
      if(sc == 0){
        region = this.region
        gerencia = this.gerencia
        cedi = this.cedi

        d1 = this.dateTimePicker[0]
        d2 = this.dateTimePicker[1]
      }else if(sc == 1){
        region = this.asigSuper.region ? this.asigSuper.region : 0
        gerencia = this.asigSuper.gerencia ? this.asigSuper.gerencia : 0
        cedi = this.asigSuper.cedi ? this.asigSuper.cedi : 0
        d1 = this.asigSuper.dateTime ? this.asigSuper.dateTime[0] : this.dateTimePicker[0]
        d2 = this.asigSuper.dateTime ? this.asigSuper.dateTime[1] : this.dateTimePicker[1]
      }

      let json ={
        c: "CallCenterRefresco",
        cmd: "_restantes",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        u_reg_id: region,
        ger_id: gerencia,
        cds_id: cedi,
        date_fr: d1,
        date_to: d2,
        sts_id: this.estatus
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0) this.restantes = data.data
        if(sc == 1) this.restantesSuper = data.data
      })
    },
    asignarSuper(){
      this.overlay = true
      let json = {
        c: "CallCenterRefresco",
        cmd: "_asignarSupervisor",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        u_reg_id: this.asigSuper.region ? this.asigSuper.region : null,
        ger_id: this.asigSuper.gerencia ? this.asigSuper.gerencia : null,
        cds_id: this.asigSuper.cedi ? this.asigSuper.cedi : null,
        date_fr: this.asigSuper.dateTime[0],
        date_to: this.asigSuper.dateTime[1],
        numasig: this.asigSuper.asignar ? this.asigSuper.asignar : 0,
        CallId: this.asigSuper.callCenter
        //ids: this.idsFiltrar
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        this.overlay = false
            this.$notify({
              title: '',
              message: 'Se realizo la Asignación con éxito.',
              verticalAlign: 'top',
              horizontalAlign: 'center',
              type: 'success',
              timeout: 10000,
              icon: 'tim-icons icon-bell-55'
            });

            this.dgAsignarReg = false
            this.getData()

      })
    },
    eventCommentRow(item){
      console.log(item);
      this.dgComment = true
      this.tlComment = item.valCar_nombre
      this.contComment = item.valCar_comments
    },
    eventEditRow(ev, item){

      console.log(item);
      var itm = item.item
      this.showEdit = true

      this.clEditar = {}
      this.clEditar.valCarId = itm.valCar_id
      this.clEditar.carId = itm.car_id
      this.clEditar.refId = itm.valCar_id_refresco
      this.clEditar.carRefId = itm.car_id_refresco
      this.clEditar.nuevo = itm.car_nuevo
      this.clEditar.intentos = itm.valCar_intentos
      this.clEditar.nud = itm.valCar_NUD
      this.clEditar.frecuencia = itm.valCar_frec_vis
      this.clEditar.nombre = itm.valCar_nombre
      this.clEditar.telFijoU = itm.valCar_tel_mot
      this.clEditar.telCelD = itm.valCar_tel_int
      this.clEditar.correo = itm.valCar_email
      this.clEditar.direccion = itm.valCar_dir
      this.clEditar.nExt = itm.valCar_num_ext
      this.clEditar.nInt = itm.valCar_num_int
      this.clEditar.colonia = itm.valCar_col
      this.clEditar.calle1 = itm.valCar_cll_1
      this.clEditar.calle2 = itm.valCar_cll_2
      this.clEditar.cp = itm.valCar_cp
      this.clEditar.comentario = itm.valCar_comments

      
    },
    valStatus(){
      if(this.clEditar.status !== 5){
        this.clEditar.razon = null
        this.clEditar.motivo = null
        this.motivos = []
      }
    },
    valCheck(){
      this.clEditar.status = null
      if(this.radioGroup == '0'){
        this.clEditar.razon = null
        this.clEditar.motivo = null
        this.motivos = []
        var xd = this.statusEdt.findIndex(v => v.i == 5)
        this.statusEdt.splice(xd, 1)
        var xd = this.statusEdt.findIndex(v => v.i == 1)
        if(xd == -1){
          this.statusEdt.splice(1,0,{ i:  1, d: 'Validado' })
        }
      }else{
        var xd = this.statusEdt.findIndex(v => v.i == 1)
        this.statusEdt.splice(xd, 1)
        var xd = this.statusEdt.findIndex(v => v.i == 5)
        if(xd == -1){
          this.statusEdt.splice(1,0,{ i:  5, d: 'Inconsistencia' })
        }
      }
    },
    actualizarCliente(){

      this.overlay = true
      let json = {
        c: "CallCenterRefresco",
        cmd: "sumbitChanges",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        vid: this.clEditar.valCarId,
        caid: this.clEditar.carId,
        refid: this.clEditar.refId,
        refCarid: this.clEditar.carRefId,
        motIcn: this.clEditar.razon ? this.clEditar.razon : 0,
        stat: this.clEditar.status ? clEditar.status : '',
        nue: this.clEditar.nuevo,
        intentos: this.clEditar.intentos,
        tipoIcn: this.clEditar.motivo ? this.clEditar.motivo : '',
        chn: JSON.stringify(this.getAllDataEdt())
      };
      

      const queryString = new URLSearchParams(json).toString();

      axios.post(this.$api_url+'?'+queryString)
      .then(data=>{
        //console.log(data.data);
        this.overlay = false
        this.showEdit = false
          this.$notify({
            title: '',
            message: 'Se actualizó el registro con éxito.',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success',
            timeout: 10000,
            icon: 'tim-icons icon-bell-55'
          });

          if(this.pagination.currentPage != 1){
            this.pagination.page = 1
            this.pagination.currentPage = 1
            this.start = 0
          }

        this.getData()
        
      })

    },
    getAllDataEdt(){
      return {
          wnd_edt_nud: this.clEditar.nud ? this.clEditar.nud : '',
          wnd_edt_frecClien: this.clEditar.frecuencia ?  this.clEditar.frecuencia : '',
          wnd_edt_nme: this.clEditar.nombre ?  this.clEditar.nombre : '',
          wnd_edt_tel: this.clEditar.telFijoU ?  this.clEditar.telFijoU : '',
          wnd_edt_tel_adi: this.clEditar.telCelD ?  this.clEditar.telCelD : '',
          wnd_edt_corrV: this.clEditar.correo ?  this.clEditar.correo : '',

          wnd_edt_dir: this.clEditar.direccion ?  this.clEditar.direccion : '',
          wnd_edt_next: this.clEditar.nExt ?  this.clEditar.nExt : '',
          wnd_edt_nint: this.clEditar.nInt ?  this.clEditar.nInt : '',
          valCar_col: this.clEditar.colonia ?  this.clEditar.colonia : '',
          wnd_edt_cll1: this.clEditar.calle1 ?  this.clEditar.calle1 : '',
          wnd_edt_cll2: this.clEditar.calle2 ?  this.clEditar.calle2 : '',
          wnd_edt_cp: this.clEditar.cp ?  this.clEditar.cp: '',
          wnd_edt_com: this.clEditar.comentario ?  this.clEditar.comentario : '',
          wnd_edt_inc: this.radioGroup ?  this.radioGroup : ''
      }
    },
   cleanNumberEdt(id, val){
      //return false
      var tmpId = id.split('-')[1]
       val = val.replace(/[^0-9]/g,'')
       this.clEditar[tmpId] = val
      //this.validaNumero(id, val)
     
    },
  },
  mounted () {
    if(localStorage.getItem('key')) {
      this.hash = localStorage.getItem('key')
      this.uid = localStorage.getItem('uid')
      this.lvl = localStorage.getItem('level')

      this.permisos = JSON.parse(localStorage.getItem('permission')).cartera_fin.actions

      var dat = new Date();
      var mo = (dat.getMonth() + 1) < 10 ? '0' + (dat.getMonth() + 1) : (dat.getMonth() + 1)
      var da = dat.getDate() < 10 ? '0' + dat.getDate() : dat.getDate()
      this.dateTimePicker[0] = dat.getFullYear() + '-' + mo + '-' + da
      this.dateTimePicker[1] = dat.getFullYear() + '-' + mo + '-' + da

      this.getRegiones(0)
      //this.getData()
      this.getModels()
      this.getTypes()
      this.getIncon()
    } else {
       this.$router.push('login');
    }
  }
}
</script>
<style>
.v-btn {
  max-height: 20px;
  text-align: center;
}

.v-btn__content {
   font-size: 10px;
}

/*
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
   text-align: left;
   background-color: rgba(0, 0, 0, .05);
}
*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 11px !important;
  /* height: 22px;*/
}
/*
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}*/

.theme--light.v-pagination .v-pagination__item--active {
  background-color: #124A98;
  color: #fff;
}

  .map {
    height: 350px;
    width: 100%;
  }

.table-trans {
  background-color: #85C1E9;
}
.table-alt {
  background-color: #45B39D;
}
.table-baj {
  background-color: #F78181;
}
.table-pen {
  background-color: #C8A2C8;
}
.table-inco {
  background-color: #efef4c;
}
.table-pro {
  background-color: #C8A2C8;
}
.table-xxx {
  background-color: #fff;
}

.truncate {
  max-width: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>