<template>
  <div class="content">
    <div class="row">
      <div class="col-2">
          <el-dropdown style="width:100%" @command="handleCommand">
            <el-button type="primary" size="mini" style="width:100%">
              Acciones<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="descargaCsv">Descargar CSV</el-dropdown-item>
              <el-dropdown-item command="descargaCsvHoras">Descargar CSV Horas</el-dropdown-item>
              <!--<el-dropdown-item command="cambiar">Cambios Masivos</el-dropdown-item>
              <el-dropdown-item command="asignar">Re Asignar</el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>
        Regiones:
          <el-select v-model="region" placeholder="REGIONES" size="mini" @change="getGerencias(0)">
            <el-option
              v-for="item in regiones"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
        <span v-if="gerencias.length != 0">Gerencias:</span>
          <el-select v-if="gerencias.length != 0" v-model="gerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(0)">
            <el-option
              v-for="item in gerencias"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="cedis.length != 0">Cedis:</span>
          <el-select v-if="cedis.length != 0" v-model="cedi" placeholder="CEDIS" size="mini" @change="getPreventa(0)">
            <el-option
              v-for="item in cedis"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="preventas.length != 0">Ruta:</span>
          <el-select v-if="preventas.length != 0" v-model="pre" placeholder="RUTAS" size="mini" multiple collapse-tags>
            <el-option
              v-for="item in preventas"
              :key="item.i+'pr'"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Frecuencia:
          <el-select v-model="frecuencia" placeholder="FRECUENCIA" size="mini" >
            <el-option
              v-for="item in frecuencias"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Fechas:
            <el-date-picker
              type="daterange"
              placeholder="Date Time Picker"
              v-model="dateTimePicker"
               size="mini"
               format="dd-MM-yyyy"
               value-format="yyyy-MM-dd"
               prefix-icon="false"
               class="el-input__icon"
               range-separator="A"
               style="font-size:8px; padding:3px 0px 3px 6px;"
               
            >
            </el-date-picker>
          <el-button size="mini" type="primary" class="mt-3" style="width:100%"
          @click="filtrar"
          >
            Filtrar
          </el-button>
        </div>
      <div class="col-10">
          <v-data-table
            dense
            :headers="headers"
            :items="desserts"
            item-key="valCar_id"
            class="elevation-1"
            :options="pagination"
            hide-default-footer
          >
          </v-data-table>
          <p>&nbsp;</p>
          <v-data-table
            dense
            :headers="headersSc"
            :items="dessertsSc"
            item-key="valCar_id"
            class="elevation-1"
            :options="pagination"
            hide-default-footer
          >
          </v-data-table>
          <!--<div
            slot="footer"
            class="col-12 my-2 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Registros <b>{{ start + 1 }}</b> A <b>{{ start + itemsT }}</b> de <b>{{ total }}</b>
              </p>
            </div>
            <b-pagination
              v-model="pagination.currentPage"
              :per-page="pagination.limit"
              :total-rows="total"
              size="sm"
              @change="newPagina"
              ></b-pagination>
          </div>-->
      </div>
    </div>
    <v-overlay :value="overlay" z-index="2002">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { TimeSelect,
          DatePicker,
          Select,
          Option,
          Button,
          Dropdown, DropdownMenu, DropdownItem, Switch, Upload, Dialog, FormItem, Form } from 'element-ui';
import axios from 'axios';

import { Loader, LoaderOptions } from "google-maps";
const loader = new Loader("AIzaSyDnsLdCKdxoFbJMxVfZHMDnr_SB-L-T7nA");

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [FormItem.name]: FormItem,
    [Form.name]: Form
  },
  data() {
    return {
      overlay: false,
      uid: null,
      hash: null,
      lvl: null,
      loading: true,
      region: null,
      regiones: [
      ],
      gerencia: null,
      gerencias: [
      ],
      cedi: null,
      cedis: [
      ],
    dateTimePicker: [],
      desserts: [],
      dessertsSc: [],
      headers: [
        {
          text: 'Ruta',
          align: 'start',
          sortable: false,
          value: 'row_id',
        },
        { text: 'Promotor', value: 'clt_crg', width: 100, sortable: false },
        { text: 'Cartera', value: 'clt_tbl', width: 150, sortable: false },
        { text: 'Visitados', value: 'clt_act', width: 150, sortable: false },
        { text: '% Avance', value: 'act_pct', width: 150,sortable: false },
        { text: 'NO CONSTESTO', value: 'clt_alt', width: 150, sortable: false },
        { text: '% NO CONSTESTO', value: 'alt_pct', width: 150, sortable: false },
        { text: 'NO DE LE INTERESA', value: 'bja_pct', width: 150, sortable: false },
        { text: 'Acepto Televenta', value: 'clt_rls', width: 150, sortable: false },
        { text: 'Inconsistencia', value: 'rls_pct', width: 150, sortable: false }
      ],
      headersSc: [
        {
          text: 'Hora',
          align: 'start',
          sortable: false,
          value: 'row_id',
        },
        { text: 'Rutas', value: 'clt_crg', width: 100, sortable: false },
        { text: 'Altas', value: 'clt_tbl', width: 150, sortable: false },
        { text: 'Transformado', value: 'clt_act', width: 150, sortable: false },
        { text: 'Bajas', value: 'act_pct', width: 150, sortable: false },
        { text: 'Visitados+Altas', value: 'clt_alt', width: 150, sortable: false }
      ],
      pageCount: 0,
      start: 0,
      total: 0,
      itemsT: 0,
      pagination: {
        page: 1,
        limit: 13,
        itemsPerPage: 13,
        currentPage: 1
      },
      dgFiltrar: false,
      resultadoMasivo: '',
      masvisoFileXls: null,
      fmSubir: false,
      dgSubir: false,
      passSubir: '',
      subirFileXls: null,
      dgCambios: false,
      fmCambios: false,
      passCambios: '',
      preventas: [],
      pre: null,
      frecuencia: null,
      frecuencias: [
        { i: '-1',   d: 'Todos' },
        { i:  'J,S', d: 'J,S' },
        { i:  'V,L', d: 'V,L' },
        { i:  'S,M',d: 'S,M' },
        { i:  'L,W',d: 'L,W' },
        { i:  'M,J',d: 'M,J' } 
      ],
      type: ''
    }
  },
  methods: {
    handleCommand(cm){
      switch (cm){
        case 'descargaCsv':
          this.descargaCsv()
        break
        case 'descargaCsvHoras':
          this.getReportDataHr()
        break
      }
    },
    newPagina(p){
      // console.log(p);
      this.pagination.page = p
      this.start = this.pagination.limit * (p - 1)
      this.pagination.currentPage = p

      this.getData()
    },
    filtrar() {
      this.getData()
      this.getDataHr()
    },
    getData() {
      this.overlay = true

      if(this.region){ this.type = 'reg'; }
      if(this.gerencia){ this.type = 'ger'; }
      if(this.cedi) { this.type = 'cds'; }
      if(this.pre){ this.type = 'rta'; }


      let json ={
        c: "Report",
        cmd: "getReportData",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        //ip: "189.167.28.167",
        rep_type: this.type,
        frec_vi: this.frecuencia ? this.frecuencia : '',
        u_reg_id: this.region ? this.region : '',
        ger_id: this.gerencia ? this.gerencia : '',
        cds_id: this.cedi ? this.cedi : '',
        rta_id: this.pre ? this.pre.join(',') : null,
        page: this.pagination.page,
        start: this.start,
        limit: this.pagination.limit,
        date_fr: this.dateTimePicker[0] ? this.dateTimePicker[0] : null,
        date_to: this.dateTimePicker[1] ? this.dateTimePicker[1] : null
      };



      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data);
        var resp = data.data.rows
        this.desserts = resp;
        this.pageCount = Math.ceil(data.data.results / this.pagination.limit)
        this.total = data.data.results
        this.itemsT = resp.length
        this.overlay = false
      })
    },
    getDataHr() {

      if(this.region){ this.type = 'reg'; }
      if(this.gerencia){ this.type = 'ger'; }
      if(this.cedi) { this.type = 'cds'; }
      if(this.pre){ this.type = 'rta'; }

      let json ={
        c: "Report",
        cmd: "getReportDataHr",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        //ip: "189.167.28.167",
        rep_type: this.type,
        frec_vi: this.frecuencia ? this.frecuencia : '',
        u_reg_id: this.region ? this.region : '',
        ger_id: this.gerencia ? this.gerencia : '',
        cds_id: this.cedi ? this.cedi : '',
        rta_id: this.pre ? this.pre.join(',') : '',
        page: this.pagination.page,
        start: this.start,
        limit: this.pagination.limit,
        date_fr: this.dateTimePicker[0] ? this.dateTimePicker[0] : null,
        date_to: this.dateTimePicker[1] ? this.dateTimePicker[1] : null
      };



      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data);
        var resp = data.data.rows
        this.dessertsSc = resp;
        /*this.pageCount = Math.ceil(data.data.results / this.pagination.limit)
        this.total = data.data.results
        this.itemsT = resp.length*/
        //this.overlay = false
      })
    },
    getRegiones(){
      let json ={
        c: "geppValida",
        cmd: "getRegionesJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{

          this.regiones = data.data
          //this.itemsRegiones = data.data
          this.regiones.unshift({ i: -1, d: 'TODAS' })
          //this.itemsRegiones.splice(0,1)
          
        
      })
    },
    getGerencias(sc){
      
      if(this.region == -1){
        this.gerencia = null
        this.cedi = null
        this.pre = null
        this.gerencias = []
        this.cedis = []
        this.preventas = []
        //this.usuarios = []

        return false
      }

        this.gerencia = null
        this.cedi = null
        this.pre = null
        this.preventas = []
        this.cedis = []

      let json ={
        c: "geppValida",
        cmd: "getGerenciasJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        reg: this.region ? this.region : this.clNuevo.region ? this.clNuevo.region : null
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.gerencias = data.data
          this.gerencias.unshift({ i: -1, d: 'TODAS' })
        }else{
          this.itemsGerencias = data.data
        }
      })
    },
    getCedis(sc){
      if(this.gerencia == -1){
        this.cedi = null
        this.cedis = []
        this.pre = null
        this.preventas = []
        return false
      }
        this.cedi = null
        this.pre = null
        this.preventas = []

      let json ={
        c: "geppValida",
        cmd: "getCedisJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        ger: this.gerencia ? this.gerencia : this.clNuevo.gerencia ? this.clNuevo.gerencia : null
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.cedis = data.data
          this.cedis.unshift({ i: -1, d: 'TODOS' })
        }else{
          this.itemsCedis = data.data
        }
      })
    },
    getPreventa(sc){
 
      if(this.cedi == -1){
        this.pre = null
        this.preventas = []
        return false
      }

      let cedi = ''
      if(sc == 0){
        cedi = this.cedi
      }else if(sc == 1){
        cedi = this.itmCedis
      }

      let json ={
        c: "geppValida",
        cmd: "getRtPrevJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        cds: cedi
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc==0) { 
        this.preventas = data.data
        this.preventas.unshift({ i: 'all', d: 'TODAS' })
        }else if(sc == 1) {
          this.itemsRutas = data.data
        }
      })
    },
    descargaCsv(){
      var str = ''
      str += this.region ? "&u_reg_id="+this.region : ''
      str += this.gerencia ? "&ger_id="+this.gerencia : ''
      str += this.cedi ? "&cds_id="+this.cedi : ''
      str += this.pre ? "&rta_id="+this.pre : ''
      str += this.type ? "&rep_type="+this.type : ''
      str += this.dateTimePicker[0] ? "&date_fr="+this.dateTimePicker[0] : ''
      str += this.dateTimePicker[1] ? "&date_to="+this.dateTimePicker[1] : ''

      window.location = this.$api_url + "?c=Report&cmd=getReportData&exec=self&uid="+this.uid+"&hash="+this.hash+"&lvl="+this.lvl+str;
    },
    descargaCsvHoras(){
      var str = ''
      str += this.region ? "&u_reg_id="+this.region : ''
      str += this.gerencia ? "&ger_id="+this.gerencia : ''
      str += this.cedi ? "&cds_id="+this.cedi : ''
      str += this.pre ? "&rta_id="+this.pre : ''
      str += this.type ? "&rep_type="+this.type : ''
      str += this.dateTimePicker[0] ? "&date_fr="+this.dateTimePicker[0] : ''
      str += this.dateTimePicker[1] ? "&date_to="+this.dateTimePicker[1] : ''

      window.location = this.$api_url + "?c=Report&cmd=getReportDataHr&exec=self&uid="+this.uid+"&hash="+this.hash+"&lvl="+this.lvl+str;
    }
  },
  mounted () {
    if(localStorage.getItem('key')) {
      this.hash = localStorage.getItem('key')
      this.uid = localStorage.getItem('uid')
      this.lvl = localStorage.getItem('level')

      var dat = new Date();
      var mo = (dat.getMonth() + 1) < 10 ? '0' + (dat.getMonth() + 1) : (dat.getMonth() + 1)
      var da = dat.getDate() < 10 ? '0' + dat.getDate() : dat.getDate()
      this.dateTimePicker[0] = dat.getFullYear() + '-' + mo + '-' + da
      this.dateTimePicker[1] = dat.getFullYear() + '-' + mo + '-' + da

      this.getRegiones()
      //this.getData()
    } else {
       this.$router.push('login');
    }
  }
}
</script>
<style>
.v-btn {
  max-height: 20px;
  text-align: center;
}

.v-btn__content {
   font-size: 10px;
}


.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
   /*font-size: 11px !important;
   height: 20px;*/
   text-align: left;
   background-color: rgba(0, 0, 0, .05);
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 11px !important;
  /* height: 22px;*/
}

.theme--light.v-pagination .v-pagination__item--active {
  background-color: #124A98;
  color: #fff;
}

  .map {
    height: 500px;
    width: 100%;
  }

</style>