<template>
  <div class="content">
    <div class="row">
      <div class="col-2">
          <el-dropdown style="width:100%" @command="handleCommand">
            <el-button type="primary" size="mini" style="width:100%">
              Acciones<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="descargaCsv">Descargar CSV </el-dropdown-item>
              <!--<el-dropdown-item command="nuevo">Crear Nuevo</el-dropdown-item>
              <el-dropdown-item command="cambiar">Cambios Masivos</el-dropdown-item>
              <el-dropdown-item command="asignar">Re Asignar</el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>
        Regiones:
          <el-select v-model="region" placeholder="REGIONES" size="mini" @change="getGerencias(0)">
            <el-option
              v-for="item in regiones"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
        <span v-if="gerencias.length != 0">Gerencias:</span>
          <el-select v-if="gerencias.length != 0" v-model="gerencia" placeholder="GERENCIAS" size="mini" @change="getCedis(0)">
            <el-option
              v-for="item in gerencias"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="cedis.length != 0">Cedis:</span>
          <el-select v-if="cedis.length != 0" v-model="cedi" placeholder="CEDIS" size="mini" @change="getPreventa(0)">
            <el-option
              v-for="item in cedis"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          <span v-if="preventas.length != 0">Ruta:</span>
          <el-select v-if="preventas.length != 0" v-model="pre" placeholder="PREVENTA" size="mini" multiple collapse-tags>
            <el-option
              v-for="item in preventas"
              :key="item.i+'pr'"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Frecuencia:
          <el-select v-model="frecuencia" placeholder="FRECUENCIA" size="mini" >
            <el-option
              v-for="item in frecuencias"
              :key="item.i"
              :label="item.d"
              :value="item.i">
            </el-option>
          </el-select>
          Fechas:
            <el-date-picker
              type="daterange"
              placeholder="Date Time Picker"
              v-model="dateTimePicker"
               size="mini"
               format="dd-MM-yyyy"
               value-format="yyyy-MM-dd"
               prefix-icon="false"
               class="el-input__icon"
               range-separator="A"
               style="font-size:8px; padding:3px 0px 3px 6px;"
               
            >
            </el-date-picker>
          <el-button size="mini" type="primary" class="mt-3" style="width:100%"
          @click="filtrar"
          >
            Filtrar
          </el-button>
        </div>
      <div class="col-10">
          <v-data-table
            dense
            :headers="headers"
            :items="desserts"
            item-key="row_id"
            class="elevation-1"
            hide-default-footer
            @click:row="eventRow"
          >
          </v-data-table>
      </div>
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { TimeSelect,
          DatePicker,
          Select,
          Option,
          Button,
          Dropdown, DropdownMenu, DropdownItem, Switch, Upload, Dialog, FormItem, Form } from 'element-ui';
import axios from 'axios';

import { Loader, LoaderOptions } from "google-maps";
const loader = new Loader("AIzaSyDnsLdCKdxoFbJMxVfZHMDnr_SB-L-T7nA");

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [FormItem.name]: FormItem,
    [Form.name]: Form
  },
  data() {
    return {
      overlay: false,
      uid: null,
      hash: null,
      lvl: null,
      loading: true,
      region: null,
      regiones: [
      ],
      gerencia: null,
      gerencias: [
      ],
      cedi: null,
      cedis: [
      ],
      pre: null,
      preventas: [
        ],
    dateTimePicker: [],
      desserts: [],
      headers: [
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'row_id', width: 250
        },
        { text: 'Cartera Inicial', value: 'car_inicial', sortable: false, width: 150 },
        
        { text: 'Acepto Televenta', value: 'modi', sortable: false, width: 150},
        { text: '% Acepto Televenta', value: 'modi_por', sortable: false, width: 150 },
        { text: 'No Contesto', value: 'pendi', sortable: false, width: 150 },
        { text: '% No Contesto', value: 'pendi_por', sortable: false, width: 150 },
        { text: 'No Desea Servicio', value: 'bajas', sortable: false, width: 150 },
        { text: '% No Desea Servicio', value: 'bajas_por', sortable: false, width: 150 },
        { text: 'Total Visitados', value: 'total_vis', sortable: false, width: 150 },
        { text: '% Avance', value: 'por_avance', sortable: false, width: 150 }
      ],
      dessertsHr: [],
      headersHr: [
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'hor',
        },
        { text: 'Total', value: 'rev', sortable: false },
        { text: 'Aceptados', value: 'alt', sortable: false},
        { text: 'Rechazados', value: 'edt', sortable: false },
        { text: 'Restantes', value: 'baj', sortable: false }
      ],
      lblType: '',
      frecuencia: null,
      frecuencias: [
        { i: '-1',   d: 'Todos' },
        { i:  'J,S', d: 'J,S' },
        { i:  'V,L', d: 'V,L' },
        { i:  'S,M',d: 'S,M' },
        { i:  'L,W',d: 'L,W' },
        { i:  'M,J',d: 'M,J' } 
      ],
      type: ''
    }
  },
  methods: {
    handleCommand(cm){
      switch (cm){
        case 'descargaCsv':
          this.descargaFiltro()
        break
      }
    },

    filtrar() {
      //this.idsFiltrar = null
      this.overlay = true
      this.getData()
    },
    getData() {

      if(this.region){ this.type = 'reg'; }
      if(this.gerencia){ this.type = 'ger'; }
      if(this.cedi) { this.type = 'cds'; }
      if(this.pre){ this.type = 'rta'; }

      let json ={
        c: "ReportDirec",
        cmd: "getReportData",
        exec: "self",
        uid: this.uid,
        hash: this.hash,

        u_reg_id: this.region,
        ger_id: this.gerencia,
        cds_id: this.cedi,
        rta_id: this.pre ? this.pre.join(',') : null,
        rep_type: this.type,//reg | ger | cds | rta
        frec_vi: this.frecuencia ? this.frecuencia : null,
        //nue_id: this.nuevo,
        //src_id: this.source,
        //page: this.pagination.page,
        //start: this.start,
        //limit: this.pagination.limit,
        date_fr: this.dateTimePicker[0],
        date_to: this.dateTimePicker[1]
      };


      axios.get(this.$api_url, {params: json})
      .then(data=>{
        console.log(data);
        var resp = data.data.rows
        this.desserts = resp;
        this.overlay= false
        //this.pagination.rowsTotal = data.data.results
        //this.isBusy = false
      })
    },
    getRegiones(){
      let json ={
        c: "geppValida",
        cmd: "getRegionesJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{

          this.regiones = data.data
          //this.itemsRegiones = data.data
          this.regiones.unshift({ i: -1, d: 'TODAS' })
          //this.itemsRegiones.splice(0,1)
          
        
      })
    },
    getGerencias(sc){

      if(this.region == -1){
        this.gerencia = null
        this.cedi = null
        this.pre = null
        this.gerencias = []
        this.cedis = []
        this.preventas = []
        this.usuarios = []

        return false
      }
        
        this.gerencia = null
        this.cedi = null
        this.pre = null
        this.cedis = []
        this.preventas = []

      let json ={
        c: "geppValida",
        cmd: "getGerenciasJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        reg: this.region ? this.region : this.clNuevo.region ? this.clNuevo.region : null
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.gerencias = data.data
          this.gerencias.unshift({ i: -1, d: 'TODAS' })
        }else{
          this.itemsGerencias = data.data
        }
      })
    },
    getCedis(sc){

      if(this.gerencia == -1){
        this.cedi = null
        this.pre = null
        this.cedis = []
        this.preventas = []

        return false
      }
        
        this.cedi = null
       this.pre = null
        this.preventas = []

      let json ={
        c: "geppValida",
        cmd: "getCedisJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        ger: this.gerencia ? this.gerencia : this.clNuevo.gerencia ? this.clNuevo.gerencia : null
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc == 0){
          this.cedis = data.data
          this.cedis.unshift({ i: -1, d: 'TODOS' })
        }else{
          this.itemsCedis = data.data
        }
      })
    },
    getPreventa(sc){

    if(this.cedi == -1){
        this.pre = null
        this.preventas = []

        return false
      }

      let json ={
        c: "geppValida",
        cmd: "getRtPrevJson",
        exec: "self",
        uid: this.uid,
        hash: this.hash,
        cds: this.cedi
      };

      axios.get(this.$api_url, {params: json})
      .then(data=>{
        //console.log(data.data);
        if(sc==0) { 
        this.preventas = data.data
        this.preventas.unshift({ i: 'all', d: 'TODAS' })
        }else {
          this.itemsRutas = data.data
        }
      })
    },
    descargaFiltro(){

      if(this.region) this.type = 'reg'
      if(this.gerencia) this.type = 'ger'
      if(this.cedi) this.type = 'cds'
      if(this.pre) this.type = 'rta'

      var str = ''
      str += this.region ? "&u_reg_id="+this.region : ''
      str += this.gerencia ? "&ger_id="+this.gerencia : ''
      str += this.cedi ? "&cds_id="+this.cedi : ''
      str += this.pre && this.pre != '' ? "&rta_id="+this.pre.join(',') : ''
      str += this.dateTimePicker[0] ? "&date_fr="+this.dateTimePicker[0] : ''
      str += this.dateTimePicker[1] ? "&date_to="+this.dateTimePicker[1] : ''

      window.location = this.$api_url + "?c=ReportDirec&cmd=getReportData&exec=self&uid="+this.uid+"&hash="+this.hash+"&lvl="+this.lvl+"&oper_id="+str+"&rep_type="+type+"&t=true";
    }
  },
  mounted () {
    if(localStorage.getItem('key')) {
      this.hash = localStorage.getItem('key')
      this.uid = localStorage.getItem('uid')
      this.lvl = localStorage.getItem('level')

      var dat = new Date();
      var mo = (dat.getMonth() + 1) < 10 ? '0' + (dat.getMonth() + 1) : (dat.getMonth() + 1)
      var da = dat.getDate() < 10 ? '0' + dat.getDate() : dat.getDate()
      this.dateTimePicker[0] = dat.getFullYear() + '-' + mo + '-' + da
      this.dateTimePicker[1] = dat.getFullYear() + '-' + mo + '-' + da

      this.getRegiones()
      //this.getData()
    } else {
       this.$router.push('login');
    }
  }
}
</script>
<style>
.v-btn {
  max-height: 20px;
  text-align: center;
}

.v-btn__content {
   font-size: 10px;
}


.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
   /*font-size: 11px !important;
   height: 20px;*/
   text-align: left;
   background-color: rgba(0, 0, 0, .05);
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 11px !important;
  /* height: 22px;*/
}


.theme--light.v-pagination .v-pagination__item--active {
  background-color: #124A98;
  color: #fff;
}

  .map {
    height: 500px;
    width: 100%;
  }

</style>